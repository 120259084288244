import {MediaMetaData, ItemData} from '../interfaces/planogram.interface';
import {VideoPoster} from 'shared/interfaces/planogram';
import {SphereItem} from '../sphere_item';
import {VideoUtils} from '../utils/video_utils';
import {VideoControlsComponent} from './video_controls';
import {SPHERE_EVENT_NAMES as EVENTS} from '../event-names';
import {sphereEventHandler} from '../custom_event_utils';
import videoPreloadVertexShader from '../../shaders/standard_vertex_shader.glsl';
import videoPreloadFragmentShader from '../../shaders/video_preload_fragment_shader.glsl';
import textureScaleVertexShader from '../../shaders/texture-scale-vertex.shader.glsl';
import textureScaleFragmentShader from '../../shaders/texture-scale-fragment.shader.glsl';
import standardVertexShader from '../../shaders/standard_vertex_shader.glsl';
import videoTransparencyFragmentShader from 'shared/renderingEngine/shaders/VideoTransparencyFragmentShader.glsl';
import videoFragmentShader from 'shared/renderingEngine/shaders/VideoFragmentShader.glsl';
import {BrowserUtils} from '../utils/browser_utils';
import {Planogram} from '../planogram';
import {
  Color,
  DataTexture,
  Group,
  LinearFilter,
  Mesh,
  RGBAFormat,
  ShaderMaterial,
  Texture,
  TextureLoader,
  Vector2,
  Vector3,
  Vector4,
  VideoTexture
} from 'three';
import {disposeMaterial} from '../utils/disposeThree';
import {Metrics} from '../metrics';
import {AppState} from '../shared/app.state';
import {MATOMO_EVENT_NAMES} from '../metric-events';
import {debugFloatPrameter} from 'shared/utils/debug';

const TOLERANCE = debugFloatPrameter('VIDEO_MASKING_TOLERANCE', 0.05);

const placeholderTexture = new DataTexture(new Uint8Array([255, 255, 255, 0]), 1, 1);
placeholderTexture.needsUpdate = true;

export class VideoComponent extends SphereItem {
  readonly autoplay: boolean;
  readonly share: boolean;
  private hideControls: boolean;
  private loop: boolean;
  private source: string;
  isVisible: boolean = false;
  fullSize: boolean = false;
  private controls?: VideoControlsComponent;
  private poster: VideoPoster;
  private maskColor: string;
  private videoMaterial: ShaderMaterial | undefined;
  private posterMaterial: ShaderMaterial | undefined;

  private wasPausedManually: boolean = false;
  private disposed: boolean = false;

  private videoMesh: Mesh;
  private preloaderMaterial: ShaderMaterial;
  private preloaderMesh: Mesh;
  private hasLoaded: boolean = false;
  private preloading: [Promise<void>, () => void] | undefined;
  private ended: boolean = false;
  private hasAudio: boolean = true;
  private cachedTime: number = 0;

  isPlaying: boolean = false;
  video: HTMLVideoElement;

  private stutteredTime: number;

  isStuttering() {
    return (this.video?.currentTime ?? 0) <= this.stutteredTime;
  }

  constructor(itemData: ItemData, planogram: Planogram) {
    super(itemData, planogram);
    const mediaData = itemData.data as MediaMetaData;
    this.autoplay = mediaData.autoplay;
    this.hideControls = mediaData.hideControls;
    this.fullSize = mediaData.fullSize;
    this.loop = mediaData.loop;
    this.poster = mediaData.poster;
    this.share = mediaData.share ?? false;
    this.maskColor = mediaData.maskColor;

    const videoUrl = mediaData.videoUrl;

    this.source = VideoUtils.sanitizeUrl(videoUrl);

    this.createVideoElement();
    this.onClickItem = this.onClickItem.bind(this);
  }

  private createVideoElement() {
    this.video = document.createElement<'video'>('video');
    this.video.setAttribute('id', 'video__' + this.itemData.id);
    document.getElementById('video-container').appendChild(this.video);

    this.video.muted = this.autoplay;
    this.video.crossOrigin = 'anonymous';
    this.video.setAttribute('playsinline', '');
    this.video.loop = this.loop ?? false;

    // without this Android browsers might fail when video is autoplayed immediately after page load with:
    // WebGL warning: texImage: Fast Tex(Sub)Image upload failed without recourse, clearing to [0.2, 0.0, 0.2, 1.0]. Please file a bug!
    this.video.style.display = 'none';

    this.video.onended = () => {
      this.ended = true;
      this.pause();
    };
    this.video.preload = 'metadata';
    this.stutteredTime = -1;
    this.video.onwaiting = () => {
      this.stutteredTime = this.video.currentTime;
    };
  }

  private canAutoplay() {
    return this.autoplay && this.isAllowedToPlay && !this.wasPausedManually && !this.ended;
  }

  private preloadVideo() {
    if (this.preloading !== undefined) return this.preloading[0];
    if (!this.hideControls) this.controls.group.visible = false;
    this.preloaderMesh.visible = !this.autoplay || !this.poster;
    let canceled: boolean = false;
    this.preloading = [
      new Promise<void>(async (resolve, reject) => {
        // resolve any redirects to prevent CORS in Safari (e. g. for Vimeo hosted videos)
        const url = await this.resolveRedirects(this.source);
        if (this.disposed || canceled) return;
        this.video.src = url;

        this.video.onerror = reject;

        const loaded = async () => {
          if (this.hasLoaded) resolve();
          if (canceled || url !== this.video.src) reject(new Error('Canceled'));
          // force the first few seconds of the video to load
          // preload = auto only loads metadata on slow connections
          // we can't access the thumbnail from the metadata
          this.video.currentTime = this.cachedTime;
          const videoElm = this.video as any;
          this.hasAudio = Boolean(
            videoElm.mozHasAudio ||
              videoElm.webkitAudioDecodedByteCount ||
              (videoElm.audioTracks && videoElm.audioTracks.length > 0)
          );
          this.hasLoaded = true;
          this.showVideo(false);
          if (this.canAutoplay()) this.play();
          if (!this.hideControls) {
            this.controls.group.visible = true;
            sphereEventHandler.listen(EVENTS.CONTROL.CLICK_ITEM, this.onClickItem);
          }
          this.preloaderMesh.visible = false;
          resolve();
        };
        this.video.onloadedmetadata = loaded;
        this.video.onloadeddata = loaded;

        // have to manually force load on Safari to display first frame
        if (this.video.readyState === 0) {
          this.video.load();
        }
      }),
      () => {
        canceled = true;
        this.unloadVideo();
      }
    ];
    return this.preloading[0];
  }

  private unloadVideo() {
    this.preloading = undefined;
    this.pause();
    this.cachedTime = this.video.currentTime;
    this.hasLoaded = false;
    this.video.onloadeddata = undefined;
    this.video.onloadedmetadata = undefined;
    this.video.onerror = undefined;
    this.video.src = '';
    this.video.load();
    this.preloaderMesh.visible = false;
    this.isAllowedToPlay = true;
    if (!this.hideControls) this.controls.group.visible = true;
  }

  onClickItem = event => {
    const nothingClicked = !event.item;
    const notClickedOnThisVideo = event.item?.id !== this.id && event.item?.video?.id !== this.id;
    if ((nothingClicked || notClickedOnThisVideo) && !this.autoplay) {
      this.pause(true);
    } else if (event.item?.video?.id === this.id) {
      Metrics.storeTheEvent(
        AppState.planogramName,
        'click',
        MATOMO_EVENT_NAMES.WEBGL_CLICK_VIDEO(
          this.itemData.name,
          this.source,
          this.video === undefined ? 0 : Math.round(this.video.duration)
        )
      );
    }
  };

  async play(): Promise<boolean> {
    this.isPlaying = true;
    return this.preloadVideo()
      .then(() => this.video.play())
      .then(() => {
        this.ended = false;
        this.showVideo(true);
        this.controls?.update();
        this.wasPausedManually = false;
        if (!this.video.muted && this.hasAudio) sphereEventHandler.emit(EVENTS.VIDEO.PLAY_WITH_AUDIO);
        return true;
      })
      .catch(e => {
        if (this.disposed || e.name === 'AbortError') {
          return false;
        }
        this.isPlaying = false;
        this.pause(false);
        console.warn(e);
        this.controls?.update();
        return false;
      });
  }

  private async resolveRedirects(url: string) {
    return fetch(url, {method: 'HEAD'}).then(response => response.url);
  }

  pause(manual: boolean = false): void {
    if (!this.hasLoaded || !this.isPlaying) return;
    this.showVideo(false);
    if (manual) {
      this.wasPausedManually = true;
    }
    this.video.pause();
    this.isPlaying = false;
    this.controls?.update();
    if (!this.video.muted && this.hasAudio) sphereEventHandler.emit(EVENTS.VIDEO.STOP_WITH_AUDIO);
  }

  isAutoPlay(): boolean {
    return this.autoplay && this.isVisible;
  }

  onClick(position: Vector3): void {
    super.onClick(position);
    this.controls?.onClick();
    Metrics.storeTheEvent(
      AppState.planogramName,
      'click',
      MATOMO_EVENT_NAMES.WEBGL_CLICK_VIDEO(this.itemData.name, this.source, Math.round(this.video.duration))
    );
  }

  update(elapsedTime: number) {
    if (this.preloaderMaterial && !this.hasLoaded) {
      this.preloaderMaterial.uniforms.time.value += elapsedTime;
    }
  }

  private showVideo(flag: boolean) {
    this.videoMesh.visible = flag || this.poster !== undefined;
    this.videoMesh.material = flag || this.poster === undefined ? this.videoMaterial : this.posterMaterial;
  }

  private createControls() {
    if (!this.hideControls) {
      this.controls = new VideoControlsComponent(this);
      this.controls.group.renderOrder = this.renderOrder + 0.2;
      this.object3D.add(this.controls.group);
    }
  }

  private async createPreloaderMaterial() {
    const opacity = this.autoplay ? 0.2 : 0.5;
    const uniforms = {
      time: {value: 0},
      aspectRatio: {value: this.width / this.height},
      color: {value: new Color(0xffffff)},
      scale: {value: 0.33},
      opacity: {value: opacity}
    };

    return new ShaderMaterial({
      vertexShader: videoPreloadVertexShader,
      fragmentShader: videoPreloadFragmentShader,
      uniforms,
      depthTest: false,
      transparent: true,
      defines: {
        PI: Math.PI
      }
    });
  }

  private async createVideoMaterial() {
    const videoTexture = new VideoTexture(this.video);
    videoTexture.generateMipmaps = false;
    videoTexture.minFilter = LinearFilter;
    videoTexture.magFilter = LinearFilter;
    videoTexture.format = RGBAFormat; // RGBFormat causes performance issues in Firefox

    return new ShaderMaterial({
      vertexShader: standardVertexShader,
      fragmentShader: this.maskColor !== undefined ? videoTransparencyFragmentShader : videoFragmentShader,
      uniforms: {
        videoTexture: {value: videoTexture},
        maskColor: {value: new Color(this.maskColor)}
      },
      defines: {
        tolerance: TOLERANCE
      },
      depthTest: false,
      transparent: true
    });
  }

  private async createPosterMaterial() {
    if (!this.poster) return;
    const textureLoader = new TextureLoader();
    const posterUrl = this.poster.url + BrowserUtils.pickImageVariant(this.poster.thumbnails, true);
    try {
      const posterTexture = await textureLoader.loadAsync(posterUrl);
      if (this.disposed) {
        posterTexture.dispose();
        return;
      }

      const videoAspect = this.width / this.height;
      const posterAspect = this.poster.naturalWidth / this.poster.naturalHeight;
      const relativePosterAspect = posterAspect / videoAspect;

      const uvScale =
        relativePosterAspect > 1 ? new Vector2(1, 1 / relativePosterAspect) : new Vector2(relativePosterAspect, 1);
      const uvOffset = relativePosterAspect > 1 ? new Vector2(0, 0.5) : new Vector2(0.5, 0);

      return new ShaderMaterial({
        vertexShader: textureScaleVertexShader,
        fragmentShader: textureScaleFragmentShader,
        uniforms: {
          imageTexture: {value: posterTexture},
          backgroundColor: {value: new Vector4(0, 0, 0, 0)},
          uvScale: {value: uvScale},
          uvOffset: {value: uvOffset}
        },
        depthTest: false,
        transparent: true
      });
    } catch (e) {
      console.error(e);
    }
  }

  onHoverEnter() {
    this.controls?.onHoverEnter();
  }

  onHoverLeave() {
    this.controls?.onHoverLeave();
  }

  private isAllowedToPlay: boolean = true;
  allowToPlay(flag: boolean) {
    this.isAllowedToPlay = flag;
    if (flag && !this.isPlaying && this.canAutoplay()) this.play();
    if (!flag && this.isPlaying) this.pause();
  }

  allowToLoad(flag: boolean) {
    if (flag) this.preloadVideo();
    else if (this.preloading !== undefined) this.preloading[1]();
    else this.unloadVideo();
  }

  async createMesh(): Promise<void> {
    this.object3D = new Group();
    this.object3D.renderOrder = this.renderOrder;
    this.object3D.userData = {
      component: this,
      itemData: this.itemData
    };
    this.object3D.layers.enable(2);

    return Promise.all([this.createPreloaderMaterial(), this.createVideoMaterial(), this.createPosterMaterial()]).then(
      ([preloaderMaterial, videoMaterial, posterMaterial]) => {
        this.preloaderMaterial = preloaderMaterial;
        this.videoMaterial = videoMaterial;
        this.posterMaterial = posterMaterial;
        this.geometry = this.generateGeometry();

        if (!this.hasLoaded) {
          const preloaderMesh = new Mesh(this.geometry, preloaderMaterial);
          preloaderMesh.layers.enable(2);
          preloaderMesh.renderOrder = this.renderOrder + 0.1;
          preloaderMesh.visible = false;
          this.preloaderMesh = preloaderMesh;
          preloaderMesh.userData = {
            component: this
          };
          this.object3D.add(this.preloaderMesh);
        }

        const videoMesh = new Mesh(this.geometry, this.poster ? this.posterMaterial : this.videoMaterial);
        videoMesh.layers.enable(2);
        videoMesh.renderOrder = this.renderOrder;
        this.object3D.add(videoMesh);
        videoMesh.userData = {
          component: this
        };
        this.videoMesh = videoMesh;

        this.createControls();
      }
    );
  }

  dispose(): void {
    this.disposed = true;
    sphereEventHandler.off(EVENTS.CONTROL.CLICK_ITEM, this.onClickItem);

    if (this.video) {
      this.unloadVideo();
      this.video.onended = undefined;
      this.video.onwaiting = undefined;

      this.video.remove();
      this.video = undefined;
    }

    disposeMaterial(this.videoMaterial);
    disposeMaterial(this.preloaderMaterial);

    this.onClickItem = undefined;

    this.controls?.dispose();
    this.controls = undefined;

    super.dispose();
  }
}
