import {SphereItem} from './sphere_item';
import {Planogram} from './planogram';
import {CLUSTER_NAME_REGEX} from './shared/constants';
import {isContentOverlayAction, SphereItemType} from 'shared/interfaces/planogram';
import {TextComponent} from './components/text';
import {VideoComponent} from './components/video';
import {
  CurvesMetaData,
  ImageMetaData,
  ItemData,
  MetaDataType,
  ClusterMetaData,
  MediaMetaData,
  ProductMetaData
} from './interfaces/planogram.interface';
import {CurveComponent} from './components/curve';
import {RectangleComponent} from './components/rectangle';
import {AppUtils} from './utils/app_utils';
import loadingProgress, {LOADING_STAGES} from './api/services/loading_progress.service';
import {Group, Object3D, WebGLCapabilities} from 'three';

function fetchVideoAspectRatio(url: string): Promise<number> {
  const videoElm = document.createElement('video');
  videoElm.preload = 'metadata';
  videoElm.src = url;
  videoElm.load();
  return new Promise(resolve => {
    videoElm.onloadedmetadata = () => {
      videoElm.remove();
      resolve(videoElm.videoWidth / videoElm.videoHeight);
    };
  });
}

async function fitFeedContent(item: SphereItem) {
  if (item.itemData.instagram_feed !== true) return;

  const size = item.getSize();
  const position = item.getPosition().addScaledVector(size, 0.5);

  let newAspectRatio: number = 1;

  if (item.type === SphereItemType.Video)
    newAspectRatio = await fetchVideoAspectRatio((item.data as MediaMetaData).videoUrl);
  else if (item.type === SphereItemType.Image) {
    const resolution = (item.data as ImageMetaData).naturalResolution;
    newAspectRatio = resolution[0] / resolution[1];
  } else {
    throw new Error(`Unknown instagram feed object of type ${item.type}`);
  }

  const containerAspectRatio = size.x / size.y;
  const contentToContainer = newAspectRatio / containerAspectRatio;

  if (contentToContainer > 1) size.y /= contentToContainer;
  else size.x *= contentToContainer;

  position.addScaledVector(size, -0.5);
  item.overridePosition(position.x, position.y);
  item.overrideSize(size.x, size.y);
}

export class SphereItems extends Group {
  readonly items: Array<SphereItem> = [];

  constructor(private planogram: Planogram) {
    super();
    this.layers.enable(2);

    this.items = this.planogram.items.map(item => this.createSphereItem(item));
  }

  loadSphereItems(capabilities: WebGLCapabilities): Promise<void> {
    const newPickingItemsMap = new Map<string, Object3D>();
    if (this.items.length === 0) loadingProgress.progressStage(LOADING_STAGES.ITEM_MESHES, 1);
    const itemPromises = this.items.map(sphereItem =>
      fitFeedContent(sphereItem)
        .then(() => sphereItem.createMesh(capabilities))
        .catch(err => {
          console.error(`Sphere item mesh creation failed: ${err}`);
        })
        .then(() => {
          loadingProgress.progressStage(LOADING_STAGES.ITEM_MESHES, this.items.length);
          if (sphereItem.type === SphereItemType.Cluster) {
            return;
          }

          this.add(sphereItem.object3D);
          const item = sphereItem.object3D.userData.component as SphereItem;
          if (item) {
            newPickingItemsMap.set(String(item.id), sphereItem.object3D);
          }
        })
    );

    return Promise.all(itemPromises).then(); // discard void[], thanks Typescript
  }

  dispose() {
    this.items.forEach(it => it.dispose());
  }

  private createSphereItem(item: ItemData<MetaDataType>) {
    switch (item.type) {
      case SphereItemType.Video:
        return new VideoComponent(item, this.planogram);
      case SphereItemType.Text:
      case SphereItemType.TextArea:
        return new TextComponent(item, this.planogram);
      case SphereItemType.Shape:
        return new RectangleComponent(item, this.planogram);
      case SphereItemType.Curve:
        return new CurveComponent(item as ItemData<CurvesMetaData>, this.planogram);
      case SphereItemType.Image:
      case SphereItemType.Product:
        // rely on material being overriden by LOD system
        return new SphereItem(item, this.planogram);
      default:
        return new SphereItem(item, this.planogram);
    }
  }

  findClusterByClusterName(clusterName: string): SphereItem | undefined {
    // handle case with raw value as cluster name
    if (clusterName.match(CLUSTER_NAME_REGEX)) {
      clusterName = AppUtils.extractClusterName(clusterName);
    }
    const foundClusters = this.items.filter(item => {
      return (item.data as ClusterMetaData).clusterLink === clusterName && item.type === SphereItemType.Cluster;
    });
    if (foundClusters.length) {
      if (foundClusters.length > 1) {
        console.error('There are more than one cluster with the same name');
      }
      return foundClusters[0];
    }
  }

  findSphereItemByIdentifier(identifier: string): SphereItem | undefined {
    return this.items.find(item => {
      return item.identifier === identifier || item.id?.toString() === identifier;
    });
  }

  findSphereItemByActionIdentifier(identifier: string) {
    return this.items.find(item => {
      return item.identifier === identifier || item.id?.toString() === identifier;
    });
  }

  findSphereItemByImageId(imageId: string) {
    return this.items.find(
      item =>
        (
          ((item.data as ProductMetaData).picture?.id?.toString() === imageId ||
            (item.data as ImageMetaData).id?.toString()) === item.id
        )?.toString() === imageId
    );
  }

  findSphereItemByContentLink(link: string) {
    return this.items.find(
      it => it.action !== undefined && isContentOverlayAction(it.action) && it.action.data.iframeLink === link
    );
  }
}
