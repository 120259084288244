import Navigo from 'navigo';
import {SphereItem} from './sphere_item';
import {UrlUtils} from './api/urls';
import {L10nUtils} from './utils/l10n_utils';
import {
  ACTION_TYPE_TO_URL_PREFIX,
  INFO_OVERLAYS,
  ITEM_TYPE_TO_URL_PREFIX_MAP,
  PRODUCT_PAGE_ROUTES
} from './shared/constants';
import {SphereItemType} from 'shared/interfaces/planogram';
import {ContentOverlayActionData} from 'shared/interfaces/planogram';
import {ActionType} from 'shared/interfaces/planogram';

function generateUrl(prefix: string, path: string, planogramName: string, withLang = false) {
  const langSegment = withLang ? `/${L10nUtils.getCurrentLanguage()}` : '';
  return `${langSegment}/${planogramName}/${prefix}${path}`;
}

export default class Router {
  private static router: Navigo;

  static get NAVIGATION_PAGES() {
    return {
      PLANOGRAM: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)$/,
      PLANOGRAM_FALLBACK: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)/,
      IMAGE: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/i([\w]+(?:(?!--|\/).)*)(?:-{2})?([\w\-_.]+)?\/?(action|skip|show)?$/,
      VIDEO: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/y([\w-]+)\/?(action|skip|show)?$/,
      TEXT: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/t([\w-]+)\/?(action|skip|show)?$/,
      SHAPE: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/s([\w-]+)\/?(action|skip|show)?$/,
      IFRAME: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/f([\w-]+)$/,
      PRODUCT_BY_CODE_AND_NAME: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/p([\w]+(?:(?!--|\/).)*)(?:-{2})?([\w-]+)?\/?([\w-]+)?$/,
      SEARCH_OVERLAY: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/search$/,
      VIDEO_OVERLAY: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/v([\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12}|[\d]+)(?:--([\w-]+))?$/,
      INFO_OVERLAY: new RegExp(
        `^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/infos\/(${Object.values(INFO_OVERLAYS).join('|')})$`
      ),
      CLUSTER_AREA: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/c(\w+(?:(?!--|\/).)*)?\/?(action|skip|show)?$/,
      CONTENT_OVERLAY: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/o(\w+)\/?$/,
      SOCIAL_MEDIA_OVERLAY: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/sm([\w-]+)$/,
      NAVIGATION_MENU: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/nm([\w-]+)$/,
      ANIMATION_PATH: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/p_((\w+(?:(?!--|\/).)*)?)\/?$/,
      CAROUSEL_OVERLAY: /^\/(?:([a-z]{2}(?:-[A-Z]{2})?)\/)?([a-z0-9-_]+)\/co_([\w-]+)$/,
      OTHER: /.*/
    };
  }

  static init(root: string) {
    if (!this.router) {
      this.router = new Navigo(root);
    }
  }

  static on(path: RegExp, handler: (...path: string[]) => void, options?) {
    this.router.on(path, handler, options).resolve();
  }

  static onBeforeRoute(callback: Function) {
    this.router.hooks({
      before: done => {
        done();
        callback();
      }
    });
  }

  static withLang(fn: (...path: string[]) => void): (...path: string[]) => void {
    return (lang, ...rest) => {
      const currentLang = L10nUtils.getCurrentLanguage();

      if (!lang) {
        L10nUtils.initialize().then(() => {
          const {pathname, search} = window.location;
          this.navigate(`/${pathname}${search}`, {replace: true, silent: true});
        });
      } else if (currentLang !== lang) {
        L10nUtils.selectLanguage(lang).then(() => {
          fn(...rest);
        });
      } else {
        fn(...rest);
      }
    };
  }

  static removeParamFromUrl(param: string) {
    const lastResolvedUrl = this.router.lastRouteResolved();
    const formattedUrl = lastResolvedUrl.url?.replace(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//, '');
    const search = new URLSearchParams(window.location.search);
    search.delete(param);
    const searchString = search.toString();
    return this.navigate(`/${formattedUrl}${searchString.length > 0 ? '?' : ''}${searchString}`, {
      replace: true,
      silent: true
    });
  }

  static navigate(url: string, {replace = false, silent = false, langCode = undefined} = {}) {
    const lang = langCode ?? L10nUtils.getCurrentLanguage();
    const urlWithLang = `/${lang}${url}`;

    if (replace) this.router.historyAPIUpdateMethod('replaceState');
    if (silent) this.router.pause();
    this.router.navigate(urlWithLang);
    if (silent) this.router.pause(false);
    if (replace) this.router.historyAPIUpdateMethod('pushState');
  }

  static updateLangCode(langCode: string) {
    L10nUtils.setCurrentLanguage(langCode);
    this.navigate(`/${L10nUtils.getCurrentPlanogram()}${window.location.search}`);
  }

  static navigateToItemOnSphere(url: string, activate: boolean = false, {replace = false, silent = false} = {}) {
    this.navigate(`${url}${activate ? '/action' : ''}`, {replace, silent});
  }

  static navigateToCluster(planogramName: string, clusterName: string, options?: {autoplay: boolean}) {
    const urlParameters = options?.autoplay ? '?autoplay=true' : '';
    this.navigate(`/${planogramName}/c${clusterName}${urlParameters}`);
  }

  static removeClusterAutoplayState() {
    if (UrlUtils.getQueryValueFromUrl('autoplay')) {
      const lastRoute = this.router.lastRouteResolved();
      const currentLang = L10nUtils.getCurrentLanguage();
      const urlWithoutLangSlug = lastRoute.url.replace(`/${currentLang}`, '');
      this.navigate(urlWithoutLangSlug);
    }
  }

  static navigateToSearch(planogramName: string, isSearchActive: boolean) {
    const searchSegment = isSearchActive ? 'search' : '';
    this.navigate(`/${planogramName}/${searchSegment}`);
  }

  static updateSearchQuery(planogramName: string, query: string) {
    const encodedQuery = encodeURIComponent(query);
    this.navigate(`/${planogramName}/search?query=${encodedQuery}`, {replace: true, silent: true});
  }

  static navigateToPlanogram(planogramName: string) {
    this.navigate(`/${planogramName}`);
  }

  static navigateToPlanogramWithLang(planogramName: string, lang: string) {
    this.navigate(`/${planogramName}`, {langCode: lang});
  }

  static navigateToPlanogramWithPath(
    planogramName: string,
    path: string,
    {langCode = undefined, replace = false, silent = false} = {},
    step?: number
  ) {
    this.navigate(`/${planogramName}/p_${path}${step !== undefined ? `?step=${step}` : ''}`, {
      langCode,
      replace,
      silent
    });
  }

  static navigateToItem(planogramName: string, id: string, type: SphereItemType, activate?: boolean) {
    this.navigateToItemOnSphere(this.generateItemUrl(id, type, planogramName), activate);
  }

  static navigateToProduct(sphereItem: SphereItem, overlayView?: PRODUCT_PAGE_ROUTES) {
    this.navigateToItemOnSphere(Router.generateProductUrl(sphereItem, overlayView));
  }

  static navigateToImage(
    imageId: string,
    imageName: string,
    planogramName: string,
    activate: boolean,
    {replace = false, silent = false}
  ) {
    this.navigateToItemOnSphere(Router.generateImageUrl(imageId, imageName, planogramName), activate, {
      replace,
      silent
    });
  }

  static navigateToIframe(iframe: SphereItem) {
    const planogramName = iframe.planogram.name;
    const iframeId = (iframe.id as string) ?? iframe.identifier;
    this.navigateToItemOnSphere(Router.generateIframeUrl(iframeId, planogramName));
  }

  static navigateToSocialMedia(item: SphereItem) {
    const planogramName = item.planogram.name;
    const itemId = (item.id as string) ?? item.identifier;
    this.navigateToItemOnSphere(Router.generateSocialMediaUrl(itemId, planogramName));
  }

  static navigateToContentOverlay(actionData: ContentOverlayActionData, planogramName: string) {
    this.navigateToItemOnSphere(Router.generateContentOverlayUrl(actionData.iframeLink, planogramName));
  }

  static navigateToProductId(productId: string, planogramName: string, overlayView?: PRODUCT_PAGE_ROUTES) {
    this.navigateToItemOnSphere(Router.generateProductUrlOnOtherSphere(productId, planogramName, overlayView));
  }

  static navigateToProductIdAndName(
    productId: string,
    productName: string,
    planogramName: string,
    overlayView?: PRODUCT_PAGE_ROUTES
  ) {
    this.navigateToItemOnSphere(
      Router.generateProductUrlOnOtherSphere(productId, planogramName, overlayView, productName)
    );
  }

  static navigateToCheckout(sphereItem: SphereItem) {
    this.navigate(Router.generateProductUrl(sphereItem, PRODUCT_PAGE_ROUTES.CHECKOUT));
  }

  static navigateToVideoOverlay(video: SphereItem) {
    const planogramName = video.planogram.name;
    const videoNameUrlSegment = video.name ? `--${UrlUtils.slugify(video.name)}` : '';
    const videoId = video.id;
    this.navigateToItemOnSphere(`/${planogramName}/v${videoId}${videoNameUrlSegment}`);
  }

  static navigateToInfoOverlay(planogramName: string, overlayType: string, silent = false) {
    this.navigate(`/${planogramName}/infos/${overlayType}`, {silent});
  }

  static navigateToNavigationMenu(planogramName: string, id: string) {
    this.navigateToItemOnSphere(Router.generateNavigationMenuUrl(id, planogramName));
  }

  static navigateToCarouselOverlay(item: SphereItem, planogramName: string) {
    const itemId = (item.id as string) ?? item.identifier;
    this.navigateToItemOnSphere(Router.generateActionUrl(itemId, ActionType.CarouselOverlay, planogramName));
  }

  static generateProductUrl(product: SphereItem, overlayView?: PRODUCT_PAGE_ROUTES, withLang = false): string {
    const planogramName = product.planogram.name;
    const productNameUrlSegment = product.name ? `--${UrlUtils.slugify(product.name)}` : '';
    let overlayViewUrlSegment = '';
    if (overlayView && overlayView !== PRODUCT_PAGE_ROUTES.GALLERY) {
      overlayViewUrlSegment = '/' + overlayView.toString().toLowerCase();
    }
    const productId = `${product.identifier}${productNameUrlSegment}${overlayViewUrlSegment}`;
    return this.generateItemUrl(productId, SphereItemType.Product, planogramName, withLang);
  }

  private static generateImageUrl(imageId: string, imageName: string, planogramName: string, withLang = false) {
    return this.generateItemUrl(`${imageId}--${imageName}`, SphereItemType.Image, planogramName, withLang);
  }

  private static generateIframeUrl(id: string, planogramName: string, withLang = false) {
    return this.generateActionUrl(id, ActionType.IframeLink, planogramName, withLang);
  }

  private static generateSocialMediaUrl(id: string, planogramName: string, withLang = false) {
    return this.generateActionUrl(id, ActionType.SocialLink, planogramName, withLang);
  }

  private static generateContentOverlayUrl(link: string, planogramName: string, withLang = false) {
    return this.generateActionUrl(link, ActionType.ContentOverlay, planogramName, withLang);
  }

  static generateAnimationPathUrl(path: string, planogramName: string, withLang = false) {
    return generateUrl('p_', path, planogramName, withLang);
  }

  private static generateProductUrlOnOtherSphere(
    productId: string,
    planogramName: string,
    overlayView?: PRODUCT_PAGE_ROUTES,
    productName: string = '',
    withLang = false
  ): string {
    let overlayViewUrlSegment = '';
    if (overlayView && overlayView !== PRODUCT_PAGE_ROUTES.GALLERY) {
      overlayViewUrlSegment = '/' + overlayView.toString().toLowerCase();
    }
    productName = productName ? `--${productName}` : '';
    const productUrlId = `${productId}${productName}${overlayViewUrlSegment}`;

    return this.generateItemUrl(productUrlId, SphereItemType.Product, planogramName, withLang);
  }

  static generateNavigationMenuUrl(id: string, planogramName: string, withLang = false) {
    return generateUrl('nm', id, planogramName, withLang);
  }

  static generateItemUrl(id: string, type: SphereItemType, planogramName: string, withLang = false) {
    return generateUrl(ITEM_TYPE_TO_URL_PREFIX_MAP[type], id, planogramName, withLang);
  }

  static generateActionUrl(path: string, type: ActionType, planogramName: string, withLang = false) {
    return generateUrl(ACTION_TYPE_TO_URL_PREFIX[type], path, planogramName, withLang);
  }

  static generateShareableLink(path: string) {
    const url = new URL(window.location.href);
    url.pathname = path;
    url.search = '';
    return url.href;
  }
}
