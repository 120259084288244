export enum ImageProcessingStatus {
  Pending = 'pending',
  Downloading = 'downloading',
  CreatingVariants = 'creating_variants',
  ValidatingDimensions = 'validating_dimensions',
  DimensionsOk = 'dimensions_ok',
  DimensionsError = 'dimensions_error',
  PreparingToCutTiles = 'preparing_to_cut_tiles',
  CuttingTiles = 'cutting_tiles',
  UploadingTiles = 'uploading_tiles',
  ErrorGenerating = 'error_generating',
  Generated = 'generated',
}

export enum AssetTypes {
  IMAGE = 'image',
  VIDEO = 'video',
}

export interface BackgroundLod {
  pageSize: number;
  pagesHigh: number;
  pagesWide: number;
  permanentLod: number;
  pageBorderWidth: number;

  // TODO: verify if these are present in editor API responses
  width: number;
  height: number;
  worstLod: number; // worst LOD of background, not sphere items
  x: number;
  y: number;
}

export interface BackgroundImage {
  id: number;
  path: string;
  url: string;
  image_name: string;
  tiles_path: string;
  status: ImageProcessingStatus;
  original_width: number;
  original_height: number;

  // TODO: available only in editor?
  thumbnails?: Thumbnails;
  groupedStatus?: GroupedImageProcessingStatus;
}

export interface BackgroundImageWithLod extends BackgroundImage {
  virtual_params?: BackgroundLod;
}

export interface PlanogramClusterData {
  clusterLink: string;
  originalClusterLink: string;
  clusterMode: ClusterMode;
  clusterGridMode: ClusterGridMode;
  gridOrder: string[];
  padding: number;
  alignToSides: boolean;
  clusterUrlManuallyUpdated: boolean;
  clusterSeoFields: ClusterSeoFields;
  accessibility: Accessibility;
}

export interface PlanogramImageData {
  id: number;
  naturalResolution: [number, number]; // used only to display sidebar info
  url: string; // TODO: investigate if usage on viewer is necessary, ankd if it's useful to pass it back and forth to BE on editor
  image_name: string;
  accessibility?: Accessibility;
  thumbnails: Thumbnails;
  isDeleted?: boolean; // TODO: should not be written by editor?
  opacity: number;
}

export interface PlanogramProductData {
  id: number;
  picture: PictureData;
  naturalResolution: [number, number];
  accessibility?: Accessibility;
  thumbnails?: Thumbnails;
  product?: Product;
  isDeleted?: boolean;
  opacity: number;
}

export interface PlanogramTextAreaData {
  alignment: TextHorizontalAlignment;
  alpha: number;
  color: string;
  fontFamily: FontFamily;
  fontSize: number;
  letterSpacing: number;
  lineHeight: number;
  text: string;
  topOffsetFromCenter: number;
  weight: string;
  verticalAlignment: TextVerticalAlignment;
  textPosition: [number, number];
  textLines: string[];
  sizeManuallyDefined: boolean;
}

export interface PlanogramVideoData {
  autoplay: boolean;
  hideControls: boolean;
  loop: boolean;
  videoUrl: string;
  poster?: VideoPoster;
  accessibility?: Accessibility;
  share?: boolean;
  maskColor?: string;
  fullSize?: boolean;
}

export interface PlanogramShapeData {
  cornerRadius: number;
  indentation: number;
  borderColor: number[];
  fillColor: number[];
  soft: boolean;
  thickness: number;
  dashed: boolean;
}

export interface PlanogramCurveData {
  color: string;
  opacity: number;
  points: number[];
  soft: boolean;
  thickness: number;
  dashed: boolean;
  scale: [number, number, number];
}

type BaseSocialFeedData = {
  source?: SocialFeedSource;
  feedType?: FeedType;
  aspectRatio?: number;
};

type PlanogramImageFeedData = BaseSocialFeedData & PlanogramImageData & { imageId?: number };
type PlanogramVideoFeedData = BaseSocialFeedData & PlanogramVideoData;

export type PlanogramSocialFeedData =
  | ({ feedType?: undefined } & BaseSocialFeedData)
  | ({ feedType: FeedType.Image } & PlanogramImageFeedData)
  | ({ feedType: FeedType.Video } & PlanogramVideoFeedData);

export type PlanogramItemData =
  | PlanogramClusterData
  | PlanogramImageData
  | PlanogramProductData
  | PlanogramTextAreaData
  | PlanogramVideoData
  | PlanogramShapeData
  | PlanogramCurveData
  | PlanogramSocialFeedData;

export interface PlanogramVersionItem<T = PlanogramItemData> {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: SphereItemType;
  name: string;
  layer: number;
  data: T;
  tag?: string; // used to keep track of Product Name and SKU
  parentId?: string;
  childrenIds?: string[];
  lockedParent?: boolean; // used to keep track of non-removeable objects like product Name and SKU
  action?: Action;
  lockedAspectRatio?: boolean;
}

export interface PlanogramItem {
  id: number;
  name: string;
  updated_at: string;
  creator: string;
  items_count: number;
  language_code: string;
  planogram_versions_statuses: String[];
}

interface PlanogramVersionBase {
  id: number;
  version: number;
  status: PlanogramStatus;
  updated_at: string; // "11 Jun,  2:53PM, 2020"
  created_at: string; // "11 Jun,  2:53PM, 2020"
}

export interface PlanogramVersion extends PlanogramVersionBase {
  fabric_state: Partial<FabricState>;
  curator_update_error: string;
  curator_update_status: 'failed' | 'success' | 'in_progress';
  status: PlanogramStatus;
  step: string;
  temporary_login: string | null;
  temporary_password: string | null;
  url: string;
  navigation_arrow?: {
    animation_path?: AnimationPath;
  };
}

export interface PlanogramGroup {
  id: number;
  name: string;
  updated_at: string;
  is_landing: boolean;
  creator: string;
  language_codes: String[];
  client_subdomain_id: number;
  planograms: PlanogramItem[];
}

export interface PlanogramData {
  id: number;
  name: string;
  seo_desc: string | null;
  planogram_versions: PlanogramVersion[];
  hosts: string[];
}

export type AnimationTransitionType =
  | 'linear'
  | 'easeIn'
  | 'easeOut'
  | 'easeInOut'
  | 'bounce'
  | 'instant';

export interface AnimationPathItem {
  name: string;
  planogramName: string;
  language: string;
  itemId: string;
  transitionType: AnimationTransitionType;
  panBeforeZoom: boolean;
  duration: number;
  delay: number;
  zoomLevel: number;
  showSeoTitle?: boolean;
  action?: Action;
}

export enum AnimationPathType {
  Custom = 'custom',
  NavigationArrows = 'navigation-arrows',
  Journey = 'journey',
}

export interface AnimationPath {
  uuid: string;
  name: string;
  type: AnimationPathType;
  items: AnimationPathItem[];
  autoplay?: boolean;
  applyFinalItemsAction?: boolean;
  loop?: boolean;
  startWithoutNavigating?: boolean;
  usedForNavArrows?: boolean;
  seoTitle: string;
  seoDescription: string;
}

type RgbArray = [number, number, number]; // [0-1] linear color space

interface SphereConfig {
  horizonLine: number;
  startingPoint: number;
  backgroundColor: RgbArray;
  backgroundImageId?: number;
  backgroundImage?: BackgroundImage;
  clustersOrder: string[];
  iframesOrder: string[];
  animationPaths: AnimationPath[];
}

interface EditorConfig {
  showGrid: boolean;
  disableClusters: boolean;
  lockedObjects: string[];
}

export interface FabricState {
  items: PlanogramVersionItem[];
  sphereConfig: SphereConfig;
  editorConfig: EditorConfig;
}

export interface PlanogramDataToSave {
  planogram_version: {
    fabric_state: FabricState;
  };
}

export function isPlanogramVersionImage(
  item: PlanogramVersionItem,
): item is PlanogramVersionItem<PlanogramImageData> {
  return item.type === SphereItemType.Image;
}
export function isPlanogramVersionCluster(
  item: PlanogramVersionItem,
): item is PlanogramVersionItem<PlanogramClusterData> {
  return item.type === SphereItemType.Cluster;
}
export function isPlanogramVersionProduct(
  item: PlanogramVersionItem,
): item is PlanogramVersionItem<PlanogramProductData> {
  return item.type === SphereItemType.Product;
}
export function isPlanogramVersionTextArea(
  item: PlanogramVersionItem,
): item is PlanogramVersionItem<PlanogramTextAreaData> {
  return item.type === SphereItemType.TextArea || item.type === SphereItemType.Text;
}
export function isPlanogramVersionVideo(
  item: PlanogramVersionItem,
): item is PlanogramVersionItem<PlanogramVideoData> {
  return item.type === SphereItemType.Video;
}
export function isPlanogramVersionShape(
  item: PlanogramVersionItem,
): item is PlanogramVersionItem<PlanogramShapeData> {
  return item.type === SphereItemType.Shape;
}
export function isPlanogramVersionCurve(
  item: PlanogramVersionItem,
): item is PlanogramVersionItem<PlanogramCurveData> {
  return item.type === SphereItemType.Curve;
}
export function isPlanogramVersionGroup(item: PlanogramVersionItem): item is PlanogramVersionItem {
  return item.type === SphereItemType.Group;
}

export function isPlanogramVersionSocialFeed(
  item: PlanogramVersionItem,
): item is PlanogramVersionItem<PlanogramSocialFeedData> {
  return item.type === SphereItemType.SocialFeed;
}
export interface Accessibility {
  title?: string;
  description?: string;
}

export enum ActionType {
  AboutUs = 'about-us',
  Animate = 'animate',
  Audio = 'audio',
  ContactUs = 'contact-us',
  ContentOverlay = 'iframe',
  CookiesPolicy = 'cookies-policy',
  ExternalLink = 'external-link',
  IframeLink = 'iframe-link',
  LegalNotice = 'copyright',
  PrivacyPolicy = 'privacy-policy',
  ProductOverlay = 'product-overlay',
  SingleImage = 'single-image',
  SocialLink = 'social-link',
  VideoOverlay = 'video-overlay',
  CarouselOverlay = 'content-carousel',
}

// TODO: have a better way to express in-progress actions in curator than undefined data, such as storing it in UI state
export interface Action<T = ActionData | undefined> {
  type: ActionType;
  data: T;
}

export interface LinkActionData {
  url: string;
}

export function isLinkAction(action: Action): action is Action<LinkActionData> {
  return (
    action.data !== undefined &&
    (action.type === ActionType.ExternalLink || action.type === ActionType.IframeLink)
  );
}

export interface ProductOverlayActionData {
  // TODO: validate if these can be undefined
  productId?: number;
  productName?: string;
  productIdentifier?: string;
  savedBy?: SearchAttribute;
  isSaved?: boolean;
}

export function isProductOverlayAction(action: Action): action is Action<ProductOverlayActionData> {
  return action.data !== undefined && action.type === ActionType.ProductOverlay;
}

export interface VideoOverlayActionData {
  videoId?: number;
  url: string;
}

export interface CarouselOverlayActionData {
  items: CarouselAssets[];
}

export interface CarouselAssets {
  contentType: string;
  seoTitle: string;
  assetType: AssetTypes;
  url: string;
  thumbnails?: Thumbnails;
  thumbnailUrl?: string;
}

export function isVideoOverlayAction(action: Action): action is Action<VideoOverlayActionData> {
  return action.data !== undefined && action.type === ActionType.VideoOverlay;
}

export function isCarouselOverlay(action: Action): action is Action<CarouselOverlayActionData> {
  return action.data !== undefined && action.type === ActionType.CarouselOverlay;
}

export interface ContentOverlayActionData {
  url: string;
  iframeLink: string;
  transparent: boolean;
}

export function isContentOverlayAction(action: Action): action is Action<ContentOverlayActionData> {
  return action.data !== undefined && action.type === ActionType.ContentOverlay;
}

export enum SocialFeedSource {
  Instagram = 'Instagram',
  Facebook = 'Facebook',
  X = 'X',
  YouTube = 'Youtube',
  TikTok = 'TikTok',
  Snapchat = 'Snapchat',
  Twitch = 'Twitch',
  Linkfire = 'Linkfire',
}

export interface SocialLinkActionData {
  contentValue: string; // the actual url from PubTool
  source: SocialFeedSource; // social media type
  contentType: string; // content type from PubTool
}

export function isSocialLinkAction(action: Action): action is Action<SocialLinkActionData> {
  return action.data !== undefined && action.type === ActionType.SocialLink;
}

export interface AnimateActionData {
  sphereName?: string; // actions sphere name to link to
  itemId?: string;
  applyItemsActionInTheEnd?: boolean; // if after animation the action should be fired on the element
  productId?: number;
  productName?: string;
  productIdentifier?: string;
  clusterLink?: string;
  itemType?: SphereItemType;
  imageName?: string;
  imageId?: number;
  name?: string;
  showSeoTitle?: boolean;
  destinationAction?: Action;
}

export function isAnimateAction(action: Action): action is Action<AnimateActionData> {
  return action.data !== undefined && action.type === ActionType.Animate;
}

export interface AudioActionData {
  id: number;
  url: string;
}

export function isAudioAction(action: Action): action is Action<AudioActionData> {
  return action.data !== undefined && action.type === ActionType.Audio;
}

export function isSingleImageAction(action: Action): action is Action<undefined> {
  return action.type === ActionType.SingleImage;
}

export function isInfoOverlayAction(action: Action): action is Action<undefined> {
  return (
    action.type === ActionType.AboutUs ||
    action.type === ActionType.ContactUs ||
    action.type === ActionType.CookiesPolicy ||
    action.type === ActionType.LegalNotice ||
    action.type === ActionType.PrivacyPolicy
  );
}

export type ActionData =
  | LinkActionData
  | ProductOverlayActionData
  | VideoOverlayActionData
  | ContentOverlayActionData
  | SocialLinkActionData
  | AnimateActionData
  | AudioActionData
  | CarouselOverlayActionData;

export function isOverlayAction(action: Action): boolean {
  return (
    action.type === ActionType.ProductOverlay ||
    action.type === ActionType.VideoOverlay ||
    action.type === ActionType.SingleImage ||
    action.type === ActionType.ContentOverlay ||
    action.type === ActionType.ContactUs ||
    action.type === ActionType.CookiesPolicy ||
    action.type === ActionType.LegalNotice ||
    action.type === ActionType.PrivacyPolicy ||
    action.type === ActionType.AboutUs ||
    action.type === ActionType.SocialLink ||
    action.type === ActionType.CarouselOverlay ||
    action.type === ActionType.IframeLink
  );
}

export interface ClusterSeoFields {
  title?: string;
  h1Tag?: string;
  description?: string;
}

// TODO: is this the same as Font?
export interface FontFamily {
  name: string;
  file_url: string;
  font_weight?: string | null;
}

export interface PictureData {
  name?: string;
  seo_title?: string;
  accessibility_description?: string;
  curator_ready?: boolean;
  id: number;
  image_name: string;
  image_size: number;
  path: string;
  tags: string[];
  url: string;
  thumbnails: Thumbnails;
  status?: ImageProcessingStatus;
  groupedStatus?: GroupedImageProcessingStatus;
  used_on_planogram: boolean;
  original_width: number;
  original_height: number;
  selected?: boolean;
  already_on_canvas?: boolean;
  isDeleted: boolean;
  content_type: string;
}

export interface Product {
  accessibility_description?: string;
  id?: number;
  identifier?: string;
  name?: string;
  category_name?: string;
  pictures_used_on_planogram?: PictureData[];
  pictures_count?: number;
  updated_at?: string;
  picture?: PictureData;
  pictures?: PictureData[];
  search_matched_attributes?: SearchAttribute[];
}

export interface Thumbnails {
  VARIANT_LARGE_WEBP: string;
  VARIANT_SMALL_WEBP: string;
  VARIANT_MEDIUM_WEBP: string;
  VARIANT_THUMBNAIL_PNG: string;
  VARIANT_THUMBNAIL_JPEG: string;
  VARIANT_THUMBNAIL_WEBP: string;
  VARIANT_THUMBNAIL_PNG_600: string;
}

export interface VideoPoster {
  url: string;
  naturalWidth: number;
  naturalHeight: number;
  thumbnails: Thumbnails;
}

export enum ClusterGridMode {
  Aspect = 'ASPECT',
  Fill = 'BALANCE',
}

export enum ClusterMode {
  Free = 'FREE',
  Grid = 'GRID',
}

export enum GroupedImageProcessingStatus {
  Pending = 'pending',
  DimensionsOk = 'dimensions_ok',
  ErrorGenerating = 'error_generating',
  DimensionsError = 'dimensions_error',
  Generated = 'generated',
}

export enum PlanogramStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  GENERATED = 'generated',
  GENERATING = 'generating',
  PUBLISHED_DRAFTED = 'published_drafted',
}

export enum SearchAttribute {
  Identifier = 'identifier',
  Name = 'name',
}

export enum TextHorizontalAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum TextVerticalAlignment {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
}

export enum SphereItemType {
  Default = 'DEFAULT',
  Group = 'GROUP',
  Image = 'IMAGE',
  Video = 'VIDEO',
  Text = 'TEXT',
  TextArea = 'TEXT_AREA',
  Particles = 'PARTICLES',
  Shape = 'SHAPE',
  Curve = 'CURVE',
  Cluster = 'CLUSTER',
  Product = 'PRODUCT',
  SocialFeed = 'SOCIAL_FEED',
  AnimationPath = 'ANIMATION_PATH', // TODO: this is not an item type
}

export enum FeedType {
  Image = 'Image',
  Video = 'Video',
}

export interface PlanogramObjectsResponse {
  clusters: PlanogramVersionItem[];
  curves: PlanogramVersionItem[];
  paths: { id: string }[];
  pictures: PlanogramVersionItem[];
  products: PlanogramVersionItem[];
  shapes: PlanogramVersionItem[];
  text_areas: PlanogramVersionItem[];
  videos: PlanogramVersionItem[];
}
