import {ActionType} from 'shared/interfaces/planogram';
import {SphereItemType} from 'shared/interfaces/planogram';

const CLIENT_NAMES = {
  LEGO: 'bricks',
  WB: 'explore',
  SELFRIDGES: 'selfridges',
  SOTHEBYS: 'sothebys',
  NINJAGO: 'legoninjagouniverse',
  AJTRACEY: 'ajtracey',
  UAL: 'ual',
  KHALILI: 'khalili',
  HARRY_POTTER: 'harrypotter',
  HARRY_POTTER_UK: 'harrypotteruk',
  HARRY_POTTER_US: 'harrypotterus',
  SASSENBACH: 'sassenbach'
};

const FONT_UNIT_RATIO = 0.0025;

const DESIGNER_PLANOGRAM_HEIGHT = 2500;

const DEFAULT_CLIENT_NAME = CLIENT_NAMES.LEGO;

const enum COOKIES_ATTRIBUTES {
  SAME_SITE_LAX = 'Lax'
}

const enum PRODUCT_PAGE_ROUTES {
  GALLERY = 'gallery',
  DETAILS = 'details',
  CHECKOUT = 'checkout',
  SHOW = 'show'
}

const PLANOGRAM_NAME = {
  MAIN: 'main',
  SUPERHEROES: 'superheroes',
  FRIENDS: 'friends',
  PINS: 'pins',
  NINJAGO: 'ninjago',
  HARRYPOTTER: 'harrypotter',
  JURASSIC: 'jurassic'
};

enum BUTTONS_NAMES {
  AMAZON = 'amazon',
  RAKUTEN = 'rakuten',
  CHECKOUT = 'checkout',
  EBAY = 'ebay',
  CUSTOM = 'custom',
  CUSTOM_PIN = 'custom_pin'
}

enum CUSTOM_OVERLAYS {
  ABOUT_US = 'about-us',
  VIDEO = 'video',
  PRIVACY_POLICY = 'privacy-policy',
  CONTACT_US = 'contact-us',
  COPYRIGHT = 'copyright',
  IFRAME = 'iframe',
  COOKIES_POLICY = 'cookies-policy'
}

const INFO_OVERLAYS = {
  ABOUT_US: CUSTOM_OVERLAYS.ABOUT_US,
  COPYRIGHT: CUSTOM_OVERLAYS.COPYRIGHT,
  PRIVACY_POLICY: CUSTOM_OVERLAYS.PRIVACY_POLICY,
  CONTACT_US: CUSTOM_OVERLAYS.CONTACT_US,
  COOKIES_POLICY: CUSTOM_OVERLAYS.COOKIES_POLICY
};

const ITEM_TYPE_TO_URL_PREFIX_MAP: Partial<Record<SphereItemType, string>> = {
  [SphereItemType.Curve]: 's',
  [SphereItemType.Shape]: 's',
  [SphereItemType.Video]: 'y',
  [SphereItemType.TextArea]: 't',
  [SphereItemType.Text]: 't',
  [SphereItemType.Image]: 'i',
  [SphereItemType.Product]: 'p',
  [SphereItemType.Cluster]: 'c'
};

export const ACTION_TYPE_TO_URL_PREFIX: Partial<Record<ActionType, string>> = {
  [ActionType.ContentOverlay]: 'o',
  [ActionType.IframeLink]: 'f',
  [ActionType.SocialLink]: 'sm',
  [ActionType.CarouselOverlay]: 'co_'
};

enum CLICK_EVENT_TYPES {
  EMPTY = 'webgl-empty',
  SPHERE = 'webgl-sphere',
  CLUSTER = 'webgl-cluster',
  PRODUCT_OVERLAY = 'webgl-product_overlay',
  LINK = 'webgl-click-link',
  CENTRAL_CARD = 'webgl-click-central_card',
  PRIVACY = 'webgl-open-privacy_overlay',
  COPYRIGHT = 'webgl-open-copyright_overlay',
  IMAGE = 'webgl-image',
  ABOUT_US = 'webgl-open-about_us_overlay'
}

enum HEATMAP_EVENT_TYPES {
  CLICK = 'click',
  MOVE = 'move',
  ZOOM_IN = 'zoom-in',
  ZOOM_OUT = 'zoom-out'
}

enum CSS_VARIABLE_TYPES {
  SHARING_BUTTON = 'share-button'
  // TODO: Add all used css variables
}

enum PRODUCT_GALLERY_ITEM {
  VIDEO = 'video',
  IMAGE = 'image'
}

enum PRODUCT_VIDEO_THUMB_TYPE {
  THUMB_PREV = 'thumbPrev',
  VIDEO_PREV = 'videoPrev'
}

enum PRODUCT_VIDEO_PROVIDER {
  HTML = 'html-video',
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  DIRECT_LINK = 'direct',
  CDN = 'cdn'
}

enum DIRECTION {
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
  DOWN = 'down'
}

enum PAGE_LOADING_TYPES {
  SPHERE = 'sphere',
  PRODUCT = 'product',
  CHECKOUT = 'checkout'
}

enum ACCOUNT_OVERLAY_TYPE {
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
  RESET_PASS = 'reset-pass',
  EMAIL_SENT = 'email-sent',
  PROFILE = 'profile'
}

enum LAYOUT_SWITCHER_TYPE {
  TEXT = 'text',
  ICON = 'icon'
}

enum ECOMMERCE_BUTTON_TYPE {
  USER_PROFILE = 'user_profile',
  SHOPPING_CART = 'shopping_cart'
}

enum COOKIES_SESSION_TYPE {
  LAST_TIME_FROM_ACTION = 'lastTimeFromAction'
}

const ACCESS_TOKEN_KEY = 'accesstoken';

const CUSTOM_HEADER_KEY = 'x-lite';

const CURRENCY_CODE_KEY = 'selected-currency-code';

const SHOPIFY_MULTIPASS_TOKEN_KEY = 'multipass_token';

const CURRENCY_KEY = 'currency';

const ANIMATION_PATH_KEY = 'animationPath';

const SPHERE_BG_EVENT_NAME = 'sphere_background';

const CLUSTER_CAPTION_REGEX = /^(cluster-).+(-caption)$/g;
const CLUSTER_NAME_REGEX = /^(cluster-)(?:(?!-caption|caption).)+$/g;
const YOUTUBE_URL_REGEX = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/g;
const VIMEO_URL_REGEX = /^((?:https?:)?\/\/)?((?:www|m|player)\.)?((?:vimeo\.com))(?:$|\/|)(\S+)?$/;

export {
  CLIENT_NAMES,
  DEFAULT_CLIENT_NAME,
  PLANOGRAM_NAME,
  CUSTOM_OVERLAYS,
  SphereItemType as SPHERE_ITEM_TYPES,
  CLICK_EVENT_TYPES,
  HEATMAP_EVENT_TYPES,
  INFO_OVERLAYS,
  CSS_VARIABLE_TYPES,
  VIMEO_URL_REGEX,
  PRODUCT_GALLERY_ITEM,
  PRODUCT_VIDEO_PROVIDER,
  DIRECTION,
  CLUSTER_CAPTION_REGEX,
  CLUSTER_NAME_REGEX,
  YOUTUBE_URL_REGEX,
  BUTTONS_NAMES,
  PRODUCT_PAGE_ROUTES,
  PAGE_LOADING_TYPES,
  ACCOUNT_OVERLAY_TYPE,
  LAYOUT_SWITCHER_TYPE,
  ACCESS_TOKEN_KEY,
  FONT_UNIT_RATIO,
  DESIGNER_PLANOGRAM_HEIGHT,
  COOKIES_ATTRIBUTES,
  PRODUCT_VIDEO_THUMB_TYPE,
  CURRENCY_CODE_KEY,
  COOKIES_SESSION_TYPE,
  SHOPIFY_MULTIPASS_TOKEN_KEY,
  ECOMMERCE_BUTTON_TYPE,
  CURRENCY_KEY,
  ANIMATION_PATH_KEY,
  ITEM_TYPE_TO_URL_PREFIX_MAP,
  CUSTOM_HEADER_KEY,
  SPHERE_BG_EVENT_NAME
};
