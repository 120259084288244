import type { IUniform, Texture } from 'three';
import { Vector2, FrontSide, ShaderMaterial } from 'three';

import type { TileMap } from './TileMap';
import {
  ATLAS_PACKING,
  EMPTY_MAP_TEXTURE,
  MIN_ATLAS_POWER,
  TEXTURE_SIZE_PACKING,
  TILES_IN_TEXTURE,
  TILE_BORDER_SIZE,
  TILE_CONTENT_SIZE,
  TILE_SIZE,
} from './parameters';

export interface ItemLodUniforms {
  itemMapCoordinates: IUniform<Vector2>;
  itemMapSize: IUniform<number>;
  contentOffset: IUniform<Vector2>;
  contentRatio: IUniform<Vector2>;
  opacity: IUniform<number>;
}

interface TileMapUniforms {
  tileMapTexture: IUniform<Texture>;
  tileMapSize: IUniform<number>; // replace with a define
}

interface LodDefines {
  TEXTURE_SIZE: string;
  TILE_SIZE: string;
  TILE_CONTENT_SIZE: string;
  BORDER_SIZE: string;
  TILES_IN_TEXTURE: string;
  EMPTY_MAP_TEXTURE: string;
  MIN_ATLAS_POWER: string;
  TEXTURE_SIZE_PACKING: string;
  ATLAS_PACKING: string;
}

type IUniforms = { [uniform: string]: IUniform };

const EMPTY_COLOR = [1, 1, 1, 0.0] as const;
const ERROR_COLOR = [1, 0, 0, 1] as const;

const vertexShader = `
  precision highp float;
  precision highp int;

  varying vec2 vUV;

  uniform vec2 contentOffset;
  uniform vec2 contentRatio;

  void main() {
    vUV = contentOffset + uv * contentRatio;
    vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
    gl_Position = projectionMatrix * modelViewPosition;
  }
`;

function makeFragmentShader(
  textureCount: number,
  emptyColor: readonly [number, number, number, number],
  errorColor: readonly [number, number, number, number],
  debug: boolean,
) {
  const textureUniforms = new Array(textureCount)
    .fill('')
    .map((_, i) => `uniform highp sampler2D physicalTexture${i};`)
    .join('\n');
  const textureSwitch = new Array(textureCount)
    .fill('')
    .map(
      (_, i) =>
        `if (abs(textureIndex - ${i.toFixed(
          1,
        )}) < 0.1) { gl_FragColor = texture2D(physicalTexture${i}, textureUV); }`,
    )
    .join('\nelse ');

  return `
    precision highp float;
    precision highp int;

    varying vec2 vUV;

    uniform highp sampler2D tileMapTexture;
    uniform highp float tileMapSize;

    uniform highp vec2 itemMapCoordinates;
    uniform float itemMapSize;
    uniform float opacity;

${textureUniforms}

    void main() {
      vec2 tileMapPixel = floor(itemMapSize * vUV);
      // read from the center of the tile map pixel to prevent blending
      vec2 tileMapUV = (floor(itemMapCoordinates + tileMapPixel) + vec2(0.5)) / tileMapSize;

      // inverse of the packTileLocation function
      vec4 packedData = texture2D(tileMapTexture, tileMapUV) * 255.0;

      float x = mod(packedData.r, TILES_IN_TEXTURE);
      float y = floor(packedData.r - x) / TILES_IN_TEXTURE;
      vec2 textureOffset = vec2(x, y);

      float textureIndex = mod(packedData.g, TEXTURE_SIZE_PACKING);
      float rcpTileSize = exp2(-(packedData.g - textureIndex) / TEXTURE_SIZE_PACKING);

      // must switch from 1.0 UV to 0.0 at the same pixel as tileMapPixel changes values
      // otherwise the wrong tile will be sampled at the edges of tiles if the pixels line up exactly on UV 0.0
      vec2 tileUV = vec2(0.0, 1.0) + vec2(1.0, -1.0) * mod(vUV * round(itemMapSize * rcpTileSize), 1.0);

      float tileRatio = exp2(packedData.b - MIN_ATLAS_POWER);

      float atlasX = mod(packedData.a, ATLAS_PACKING);
      float atlasY = (packedData.a - atlasX) / ATLAS_PACKING;
      vec2 atlasOffset = vec2(atlasX, atlasY) / ATLAS_PACKING;

      float borderSize = (1.0 - tileRatio) < 0.1 ? BORDER_SIZE : 0.0;

      vec2 texturePixel = vec2(borderSize) + textureOffset * TILE_SIZE + floor((atlasOffset + tileUV * tileRatio) * (TILE_SIZE - 2.0 * borderSize));
      vec2 textureUV = texturePixel / TEXTURE_SIZE;

${textureSwitch}
      else if (abs(textureIndex - EMPTY_MAP_TEXTURE) < 0.1) {
        gl_FragColor = vec4(${emptyColor.map(v => v.toFixed(1)).join(', ')});
      }
      else {
        gl_FragColor = vec4(${errorColor.map(v => v.toFixed(1)).join(', ')});
      }

      ${
        debug ? `gl_FragColor *= vec4(tileUV, textureIndex / ${textureCount.toFixed(1)}, 1.0);` : ''
      }

      gl_FragColor.w *= opacity;
    }
  `;
}

export default class LodMaterial extends ShaderMaterial {
  constructor(
    tileMap: TileMap,
    physicalTextures: Texture[],
    physicalTextureResolution: number,
    debug: boolean,
  ) {
    const uniforms: TileMapUniforms & ItemLodUniforms & IUniforms = {
      tileMapTexture: { value: tileMap.texture },
      tileMapSize: { value: tileMap.resolution },
      contentOffset: { value: new Vector2() },
      contentRatio: { value: new Vector2() },
      itemMapCoordinates: { value: new Vector2() },
      itemMapSize: { value: 0 },
      opacity: { value: 1 },
    };
    physicalTextures.forEach((texture, index) => {
      uniforms[`physicalTexture${index}`] = { value: texture };
    });
    const defines: LodDefines = {
      TEXTURE_SIZE: physicalTextureResolution.toFixed(1),
      TILE_SIZE: TILE_SIZE.toFixed(1),
      TILE_CONTENT_SIZE: TILE_CONTENT_SIZE.toFixed(1),
      BORDER_SIZE: TILE_BORDER_SIZE.toFixed(1),
      TILES_IN_TEXTURE: TILES_IN_TEXTURE.toFixed(1),
      EMPTY_MAP_TEXTURE: EMPTY_MAP_TEXTURE.toFixed(1),
      MIN_ATLAS_POWER: MIN_ATLAS_POWER.toFixed(1),
      TEXTURE_SIZE_PACKING: TEXTURE_SIZE_PACKING.toFixed(1),
      ATLAS_PACKING: ATLAS_PACKING.toFixed(1),
    };
    const fragmentShader = makeFragmentShader(
      physicalTextures.length,
      EMPTY_COLOR,
      debug ? ERROR_COLOR : EMPTY_COLOR,
      debug,
    );

    super({
      uniforms,
      defines,
      vertexShader,
      fragmentShader,
      side: FrontSide,
      transparent: true,
      lights: false,
      depthTest: false,
      depthWrite: false,
      wireframe: false,
    });
  }

  setItemUniforms(uniforms: Partial<ItemLodUniforms>) {
    this.opacity = uniforms.opacity?.value ?? this.opacity;
    Object.entries(uniforms).forEach(([key, uniform]) => {
      this.uniforms[key].value = uniform!.value;
    });
    this.needsUpdate = true;
  }

  onBeforeRender() {
    if (this.opacity !== this.uniforms.opacity.value) {
      this.uniforms.opacity.value = this.opacity;
      this.needsUpdate = true;
    }
  }
}
