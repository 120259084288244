import {
  Action,
  AnimationPath,
  AnimationTransitionType,
  BackgroundImageWithLod,
  BackgroundLod,
  PlanogramClusterData,
  PlanogramCurveData,
  PlanogramImageData,
  PlanogramProductData,
  PlanogramShapeData,
  PlanogramTextAreaData,
  PlanogramVideoData,
  SphereItemType,
  Thumbnails
} from 'shared/interfaces/planogram';
import {BaseLodData} from 'shared/lod/interfaces';

export interface MetaData {
  opacity?: number;
  product?: {
    id: number | string;
    identifier: string;
    name: string;
  };
  // TODO: unused?
  item?: {
    id: number;
    name: string;
    action: Action;
    link: string;
  };
  name: string;
  description: string;
  color?: string;
}

export type MediaMetaData = PlanogramVideoData & MetaData;
export type TextMetaData = PlanogramTextAreaData & MetaData;
export type ClusterMetaData = PlanogramClusterData & MetaData;
export type ShapesMetaData = PlanogramShapeData & MetaData;
export type CurvesMetaData = PlanogramCurveData & MetaData;

export type LODSMetaData = BaseLodData & MetaData;
export type ImageMetaData = PlanogramImageData & LODSMetaData;
export type ProductMetaData = PlanogramProductData & LODSMetaData;

export type MetaDataType =
  | ImageMetaData
  | ProductMetaData
  | MediaMetaData
  | TextMetaData
  | ShapesMetaData
  | ClusterMetaData
  | CurvesMetaData;

export interface ItemData<T = MetaDataType> {
  id: string;
  layer: number;
  parentId?: number | string;
  childrenIds?: (number | string)[];
  type: SphereItemType;
  x: number;
  y: number;
  width: number;
  height: number;
  action?: Action;
  name?: string;
  data?: T;

  parent?: ItemData;
  children?: ItemData[];

  renderOrder?: number;

  instagram_feed?: boolean; // used for SocialFeed objects
  lods?: ItemLOD[];
}

// same as LodLevelData
export interface ItemLOD {
  lod: number;
  textures: Array<null | ItemTile>;
  url_start: string;
}

interface UV {
  x: number;
  y: number;
  height: number;
  width: number;
}

// same as TileLodData
export interface ItemTile {
  url: string;
  uv?: UV;
}

export enum ANIMATION_TYPE {
  ZOOM = 'zoom',
  DRAG = 'drag',
  CLICK = 'click'
}

export enum ANIMATION_POSITION {
  CENTER = 'center',
  TOP = 'top',
  BOTTOM = 'bottom'
}

export enum ALIGNMENT_TYPES {
  LEFT = 'left',
  MIDDLE = 'middle',
  RIGHT = 'right',
  DISTRIBUTE = 'distribute'
}

export enum NAVIGATION_ARROW_TYPES {
  LEFT = 'left',
  RIGHT = 'right'
}

interface PlanogramVersionSettingsImage {
  id: number;
  url: string;
}

export interface PlanogramVersionSearchSettings {
  id: number; // id of planogram_version
  planogram_name: string; // name of the planogram
  search_setting: {
    id: number;
    control_button: {
      id: number;
      url: string; // url to img
    };
  };
}

export interface PlanogramVersionSettings {
  enabled: boolean;
  navigation_type: string; // 'url' or 'planogram' or 'back'
  navigation_value: string; // absolute link in case of 'url', planogram name in case of 'planogram'
  language_code: string;
  open_in_new_page: boolean;
  element_type: NAVIGATION_BUTTON_TYPE;
  title: string;
  show_shadow: boolean;
  show_title: boolean;
  planogram_version_control_button_font: PlanogramFonts;
}

export interface PlanogramVersionControlButtonMenuItem {
  id: number;
  menu_id: number;
  menu_item_font: PlanogramFonts;
  navigation_type: 'internal' | 'external';
  navigation_value: string;
  control_button: PlanogramVersionSettingsImage;
  control_button_id: number;
  show_title: boolean;
  title: string;
  open_in_new_page: boolean;
  without_navigation: boolean;
}

export interface PlanogramVersionControlButtonMenu {
  background_container: boolean;
  background_transparent: boolean;
  color: string;
  id: number;
  menu_items: PlanogramVersionControlButtonMenuItem[];
  show_shadow: boolean;
}

export interface PlanogramVersionSettingsControlButton extends PlanogramVersionSettings {
  control_button: PlanogramVersionSettingsImage;
  image_size: number; // size in %
  menu?: PlanogramVersionControlButtonMenu;
}

export interface PlanogramVersionSettingsLogo extends PlanogramVersionSettings {
  logo: PlanogramVersionSettingsImage;
}

export interface PlanogramVersionSettingsInfoButton {
  id: number;
  color: string;
  custom_icon_url: string; // custom uploaded icon
  default_icon_url: string; // initial icon from system; not changeable
}

export interface EcommercePlatformCurrency {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  symbol: string;
}

export interface AnimationOptions {
  duration?: number;
  delay?: number;
  transitionType?: AnimationTransitionType;
  panBeforeZoom?: boolean;
  zoomStopPoint?: number;
  wait?: number;
}

export interface PlanogramVersionResponse {
  planogram_version: PlanogramVersion;
}

export interface PlanogramVersion {
  id: number; // 24 (version id)
  planogram_id: number; // 84
  version: number; // 3
  status: string; // published
  actions_json: PlanogramData;
  enabled_gallery_overlay: boolean;
  info_button_setting: PlanogramVersionSettingsInfoButton;
  tiles_path: string;
  tiles_paths?: {
    webp?: string;
  };
  planogram_version_control_buttons: PlanogramVersionSettingsControlButton[];
  planogram_version_logo: PlanogramVersionSettingsLogo;
  planogram_legacy: boolean;
  primary_color: string;
  secondary_color: string;
  sharing_button: SharingButton;
  camera_position: number;
  client: {
    is_external_domain: boolean; // flag for setting Cookies in correct scope
    product_images_required: boolean;
    name: string;
    ecommerce_platform_enabled: boolean; // flag for ecommerce platforms
    shopping_platform: {
      title: string;
      currency: string;
    };
    currencies: Array<EcommercePlatformCurrency>;
    shopify_multipass_enabled: boolean;
    shopify_multipass_url: string;
  };
  ecommerce_overlay_settings_pdp: EcommerceOverlaySettings;
  ecommerce_overlay_settings_shopping_cart: EcommerceOverlaySettings;
  ecommerce_overlay_settings_buttons: EcommerceOverlaySettings;
  ecommerce_overlay_settings_sign_in: EcommerceOverlaySettings;
  seo_title: string;
  seo_desc: string;
  planogram_version_products_seo: [{id: number; seo_title: string}];
  planogram_version_items_seo: [{id: number; seo_title: string}];
  iframe_primary_color: string;
  iframe_secondary_color: string;
  client_social_medias: ClientSocialMedia[];
  animation_settings: AnimationSettings;
  audio: AudioSettings | null;
  other_assets: OtherAsset[];
  audio_background_color: string;
  volume: number;
  entrance_animation: EntranceAnimationSettings;
  navigation_alignment: ALIGNMENT_TYPES;
  navigation_distribute_evenly: boolean;
  animation_paths: AnimationPath[];
  navigation_arrow: NavigationArrow | null;
  background_images: BackgroundImageWithLod[];
}

export class PlanogramData {
  width: number;
  height: number;
  topLimit: number;
  bottomLimit: number;
  backgroundColor: [number, number, number];
  startPoint: number;
  planogramVersion: string;
  clustersOrder: Array<string>;
  animation_paths: AnimationPath[];
  itemsOrder: Array<string>;
  items: Array<ItemData>;
  virtualTexture: BackgroundLod;
  productSEO: {[id: string]: {title: string}};
  itemSEO: {[id: string]: {title: string}};
  clientSocialMedias: ClientSocialMedia[];
  entranceAnimation: EntranceAnimationSettings;
  navigationArrow: NavigationArrow;
}

export interface PurchasingFlowSettingsData {
  id: number;
  purchasing_flow: PURCHASING_FLOW;
}

export interface ClientSocialMedia {
  close_bg_color: string;
  close_icon_color: string;
  container_color: string;
  container_transparent: boolean;
  content: ClientSocialMediaContent[];
  id: number;
  social_media_content_types: Array<string>;
  social_media_id: number;
  social_media_name: string;
  social_media_title: string;
  hide_overlay_container: boolean;
  scroll_container_fill: string;
  scroll_indicator_fill: string;
  show_loader: boolean;
}

export interface ClientSocialMediaContent {
  content_type: string;
  content_value: string;
}

export interface AnimationSettings {
  duration: number;
  autoplay_delay: number;
  transition_type: AnimationTransitionType;
  pan_before_zoom: boolean;
}

export interface AudioSettings {
  id: number;
  seo_desc: string;
  seo_title: string;
  url: string;
  file_name: string;
  content_type: string;
}

export interface EntranceAnimationSettings {
  entrance_animation_fonts: EntranceAnimationTypes[];
  position: ANIMATION_POSITION;
  background_color: string;
  with_animation: boolean;
  disable_glow: boolean;
  hide_guidance: boolean;
  repeat_animation: boolean;
}

interface EntranceTextSettings extends PlanogramFonts {
  title: string;
}

export interface EntranceAnimationTypes {
  action_type: ANIMATION_TYPE;
  desktop: EntranceTextSettings;
  mobile: EntranceTextSettings;
  complete: boolean;
  hidden: boolean;
  other_asset: {
    id: number;
    url: string;
  } | null;
}

export interface OtherAsset {
  id: number;
  path: string;
  url: string;
  image_name: string;
  seo_title: string;
  accessibility_description: string | null;
  thumbnails: Thumbnails;
  is_system: boolean;
  title: 'stop' | 'mute' | 'unmute';
}

export interface NavigationArrowAsset {
  direction: NAVIGATION_ARROW_TYPES;
  other_asset: OtherAsset;
}

export interface NavigationArrow {
  id: number;
  animation_path: AnimationPath;
  animation_path_id: string;
  background_color: string;
  disable_glow_effect: boolean;
  distribute_arrows: boolean;
  enabled: boolean;
  planogram_version_id: number;
  touch_devices_only: boolean;
  navigation_arrow_other_assets: NavigationArrowAsset[];
}

export interface FontData {
  id: number;
  file_url: string;
  filename: string;
  name: string;
}

export interface PlanogramFonts {
  id: number;
  font_id: number;
  color: string;
  font_size: string;
  font_weight: string;
  letter_spacing: string;
  line_height: string;
  font: FontData;
  assignment: string;
}

export enum NAVIGATION_BUTTON_TYPE {
  CONTROL_BUTTON = 'control_button',
  SEARCH = 'search_setting',
  MENU = 'menu'
}

export const enum PURCHASING_FLOW {
  CHECKOUT = 'checkout',
  BUY_BUTTON = 'buy-button'
}
export interface EcommerceOverlaySettings {
  background_color: string; // '#ffffff'
  secondary_color: string;
  controls_color?: string;
  ecommerce_text_fields?: {
    border_width: string;
    border_color: string;
  };
  button_shape: string; // 'square'
  ecommerce_buttons: Array<EcommerceButtons>;
  ecommerce_icon_buttons: Array<EcommerceIconButton>;
  ecommerce_fonts: Array<PlanogramFonts>;
  enable_buttons: boolean;
  show_details: boolean;
}

export interface EcommerceIconButton {
  button_type: string;
  color: string;
  ecommerce_overlay_setting_id: number;
  enabled: boolean;
  id: number;
  other_asset: {
    id: number;
    url: string;
  };
  other_asset_id: number;
}

export interface EcommerceButtons {
  use_border: boolean;
  border_color: string;
  border_color_disabled: string;
  border_width: string;
  use_border_disabled: boolean;
  border_width_disabled: string;
  button_type: string;
  color: string;
  color_disabled: string;
  ecommerce_overlay_setting_id: number;
  icon_url: string;
  id: number;
  title: string;
}

interface SharingButtonIcon {
  id: number;
  url: string;
  icon_state: string;
  thumbnails: any;
}

export interface SharingButton {
  id: number;
  enabled: boolean;
  title: string;
  color: string;
  sharing_button_font: PlanogramFonts;
  button_icons: Array<SharingButtonIcon>;
  sharing_providers: Array<SharingProviderIcon>;
}

export interface SharingProviderIcon {
  id: number;
  title: string;
  enabled: boolean;
  identifier: ShareProviderTypes;
  icon_custom_url: string;
  icon_default_url: string;
}

export enum ShareProviderTypes {
  WHATSAPP = 'whatsapp',
  TELEGRAM = 'telegram',
  VIBER = 'viber',
  TWITTER = 'twitter',
  COPY_LINK = 'copy_link'
}

export enum ShareButtonIconTypes {
  DEFAULT = 'default',
  ACTIVE = 'active'
}
