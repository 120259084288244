import {SphereGeometry} from 'shared/geometries/SphereGeometry';
import {Planogram} from './planogram';
import {ImageMetaData, ItemData, MetaData, ProductMetaData} from './interfaces/planogram.interface';
import {Action, isProductOverlayAction} from 'shared/interfaces/planogram';
import {SphereItemType} from 'shared/interfaces/planogram';
import {ComponentInterface} from './components/component_interface';
import {CurveGeometry} from './geometries/curve_geometry';
import {disposeObject3D} from './utils/disposeThree';
import {Group, Material, Mesh, Vector2, Vector3, WebGLCapabilities} from 'three';
import {SphereShape} from 'shared/utils/SphereShape';
import {isLodItem} from './utils/planogram_utils';

export class SphereItem implements ComponentInterface {
  protected geometry: SphereGeometry | CurveGeometry;
  object3D: Group;
  imageName: string;
  material: Material | undefined;
  planogram: Planogram;
  readonly type: SphereItemType;
  readonly id: number | string;
  readonly identifier: string;
  readonly name: string;
  // TODO: make readonly after text rendering supports alignment
  protected x: number;
  protected y: number;
  protected width: number;
  protected height: number;
  protected azimuthStartRadians: number;
  action?: Action;
  data: MetaData;
  readonly renderOrder: number;
  readonly itemData: ItemData;

  overridePosition(x: number, y: number) {
    this.x = x;
    this.y = y;

    this.updateAzimuthStartRadians();
  }

  overrideSize(width: number, height: number) {
    this.width = width;
    this.height = height;
    this.updateAzimuthStartRadians();
  }

  private updateAzimuthStartRadians() {
    this.azimuthStartRadians = SphereGeometry.calcAzimuthStartRadians(
      this.x,
      Math.max(0, this.width),
      this.planogram.width
    );
  }

  constructor(params: ItemData, planogram: Planogram) {
    this.planogram = planogram;
    this.itemData = params;
    this.data = params.data as MetaData;
    this.id = params.id;
    this.x = params.x;
    this.y = params.y;
    this.width = params.width;
    this.height = params.height;
    this.updateAzimuthStartRadians();

    // TODO: is this useful at all? It used to be set to data.imageName which was always undefined for both images and products
    this.imageName = (this.data as ImageMetaData).image_name ?? (this.data as ProductMetaData)?.picture?.name;
    this.renderOrder = params.renderOrder;

    if (params.action !== undefined && isProductOverlayAction(params.action)) {
      this.data.product = {
        id: params.action.data?.productId || this.data.product?.id,
        identifier: params.action.data?.productIdentifier || this.data.product?.identifier,
        name: params.action.data?.productName || this.data.product?.name
      };
    }

    if (params.data?.product?.name) {
      this.name = params.data.product.name;
    } else if (params.data?.item?.name) {
      // TODO: is this branch ever hit?
      this.name = params.data.item.name;
    }

    if (params.data?.product?.identifier) {
      this.identifier = params.data.product.identifier;
    } else if (params.data?.product?.id) {
      this.identifier = params.data.product.id.toString();
    }

    if (params.action) {
      this.action = params.action;
    }

    this.type = params.type;
  }

  createMesh(_: WebGLCapabilities): Promise<void> {
    this.geometry = this.generateGeometry();
    this.object3D = new Group();

    const mesh = new Mesh(this.geometry, this.material);

    if (
      ![
        SphereItemType.Image,
        SphereItemType.Product,
        SphereItemType.Text,
        SphereItemType.TextArea,
        SphereItemType.Video,
        SphereItemType.Shape
      ].includes(this.type as SphereItemType) ||
      ((this.type === SphereItemType.Image || this.type === SphereItemType.Product) && !isLodItem(this.itemData))
    ) {
      this.object3D.visible = false;
    }

    this.object3D.renderOrder = this.renderOrder;
    // Render order is added to the mesh to make it easier to sort elements when dealing only with meshes.
    mesh.renderOrder = this.renderOrder;
    mesh.userData = {
      component: this
    };
    this.object3D.add(mesh);
    if (this.hasInput()) {
      mesh.layers.enable(2);
      this.object3D.layers.enable(2);
    }

    return Promise.resolve();
  }

  protected hasInput() {
    return this.action !== undefined || this.type === SphereItemType.Video;
  }

  protected generateGeometry(): SphereGeometry {
    const azimuthLength = SphereGeometry.calcAzimuthLengthRadians(Math.abs(this.width), this.planogram.width);
    const cols = Math.max(1, Math.ceil(azimuthLength / ((2 * Math.PI) / Planogram.COLUMN_COUNT)));
    const rows = Math.max(1, Math.ceil(Math.abs(this.height) / (this.planogram.height / Planogram.ROW_COUNT)));
    const isReflectedOnX = Math.sign(this.width) < 0;

    // us are calculated going backwards from the right
    // since texture is on outside of sphere
    const geom = new SphereGeometry(
      Planogram.ALPHA,
      this.planogram.largeRadius,
      this.planogram.fixedRadius,
      cols,
      rows,
      this.azimuthStartRadians,
      azimuthLength,
      this.planogram.height,
      this.y,
      this.height,
      true,
      new Vector2(isReflectedOnX ? 0 : 1, 1),
      new Vector2(isReflectedOnX ? 1 : 0, 0)
    );

    return geom;
  }

  onClick(position: Vector3): void {}

  onHoverEnter(): void {}

  onHoverLeave(): void {}

  getPosition() {
    return new Vector2(this.x, this.y);
  }

  getSize() {
    return new Vector2(Math.abs(this.width), Math.abs(this.height));
  }

  getViewportCenter() {
    return new Vector2(
      this.x + 0.5 * Math.abs(this.width),
      this.y + 0.5 * Math.abs(this.height) - this.planogram.height * 0.25
    );
  }

  getCenter(sphereShape: SphereShape): [Vector3, Vector3] {
    const planogramCenter = this.getViewportCenter();
    const sphereCenter = sphereShape.fromPlanogramCoordinateViewer(planogramCenter, this.planogram.size());
    return [sphereShape.sample(sphereCenter), sphereShape.normalAt(sphereCenter).multiplyScalar(-1)];
  }

  dispose(): void {
    disposeObject3D(this.object3D);
    this.geometry?.dispose();
    this.geometry = undefined;
    this.material?.dispose();
    this.material = undefined;
  }
}
