import { Vector2 } from 'three';

import type PlanogramPoint from 'shared/utils/PlanogramPoint';

import type {
  ImageData,
  ImageLodData,
  LodId,
  LodLevelData,
  TileLodData,
} from 'shared/lod/interfaces';
import { TILE_CONTENT_SIZE, TILE_SIZE } from 'shared/lod/parameters';
import { BackgroundImageWithLod } from 'shared/interfaces/planogram';

export function skipAtlasLevels(lods: LodLevelData[]) {
  const atlasLess = lods.filter(it => it.textures[0]?.uv === undefined);
  // use best atlased level if no non-atlas levels are available
  if (atlasLess.length === 0) atlasLess.push(lods[0]);
  return atlasLess;
}

export function computeLevelMapSize(levelData: LodLevelData): number {
  return Math.round(Math.sqrt(levelData.textures.length));
}

export function computeImageMapSize(lodData: ImageLodData): number {
  if (lodData.curator_lods.length === 0) return 1;
  return computeLevelMapSize(lodData.curator_lods[0]);
}

export function lowestNonAtlasLevel(lodData: ImageLodData): LodLevelData {
  // TODO: consider a more efficient way to find last non-atlas level?
  for (let i = lodData.curator_lods.length - 1; i >= 0; i--) {
    const it = lodData.curator_lods[i];
    if (!isAtlas(it)) return it;
  }
  return lodData.curator_lods[0]; // treat the best atlas level as lowest level
}

export function levelRatio(lodData: ImageLodData, level: number): number {
  let lodLevel = lodData.curator_lods[level];
  if (isAtlas(lodLevel)) lodLevel = lowestNonAtlasLevel(lodData);
  return 1 << lodLevel.lod;
}

export function computeTileMapLocation(
  lodData: ImageLodData,
  level: number,
  index: number,
  tileMapOffset: Vector2,
) {
  const lodLevel = lodData.curator_lods[level];
  const levelSize = computeLevelMapSize(lodLevel);
  const tileSize = levelRatio(lodData, lodLevel.lod);
  return new Vector2(Math.floor(index / levelSize), levelSize - 1 - (index % levelSize))
    .multiplyScalar(tileSize)
    .add(tileMapOffset);
}

export function mapOffsetToIndex(image: ImageData, mapOffset: Vector2, level: number) {
  const lodLevel = image.lodData.curator_lods[level];
  const levelSize = computeLevelMapSize(lodLevel);
  const tileSize = levelRatio(image.lodData, level);
  const tileMapOffset = mapOffset.clone().sub(image.mapPosition).divideScalar(tileSize);
  return levelSize * Math.floor(tileMapOffset.x) + levelSize - 1 - Math.floor(tileMapOffset.y);
}

export function imageChunkPlanogramPosition(image: ImageData, mapOffset: Vector2) {
  const chunkSize = imagePlanogramChunkSize(image);
  const imageMapSize = computeImageMapSize(image.lodData);
  return image.extraData.position.clone().add(
    mapOffset
      .clone()
      .sub(image.mapPosition)
      .subScalar((imageMapSize - 1) * 0.5) // offset to center of image
      .multiplyScalar(chunkSize),
  );
}

export function iterateChunkPoints(
  image: ImageData,
  callback: (mapOffset: Vector2, point: PlanogramPoint) => void,
) {
  const imageMapSize = computeImageMapSize(image.lodData);
  const lodLevel = image.lodData.curator_lods[0];
  for (let x = 0; x < imageMapSize; x++) {
    for (let y = 0; y < imageMapSize; y++) {
      const index = x + y * imageMapSize;
      const mapOffset = computeTileMapLocation(
        image.lodData,
        lodLevel.lod,
        index,
        image.mapPosition,
      );
      const planogramPosition = imageChunkPlanogramPosition(image, mapOffset);
      callback(mapOffset, planogramPosition);
    }
  }
}

export function imagePlanogramChunkSize(image: ImageData): number {
  const baseLod = image.lodData.curator_lods.find(it => it.lod === 0)!;
  const baseUV = baseLod.textures[0]?.uv?.width ?? 1.0;
  // TOOD: can this just be TILE_CONTENT_SIZE?
  const pixelsPerTile =
    (Math.max(...image.lodData.full_size) * baseUV) / computeLevelMapSize(baseLod);

  // TODO: measure tiles in 2d for images with changed aspect ratio?
  const imageScaling = Math.max(
    image.extraData.size.x / image.lodData.fit_size[0],
    image.extraData.size.y / image.lodData.fit_size[1],
  );
  return imageScaling * pixelsPerTile;
}

export function isAtlas(lodLevel: LodLevelData): boolean {
  return lodLevel.textures[0]?.uv !== undefined;
}

export function worstLevel(lodData: ImageLodData) {
  return lodData.curator_lods.reduce((worst, it) => Math.max(worst, it.lod), 0);
}

export function unloadedLevelEquivalent(lodData: ImageLodData, unloadedLevelBias: number) {
  return worstLevel(lodData) + unloadedLevelBias;
}

export function nearestLevel(lodData: ImageLodData, targetLevel: number): number {
  const levels = lodData.curator_lods;
  const exactLevel = levels[targetLevel];
  if (exactLevel !== undefined) return exactLevel.lod;
  else if (targetLevel < 0) return 0;
  else return levels.length - 1;
}

export function alignMapOffset(image: ImageData, level: number, mapOffset: Vector2) {
  const tileSize = levelRatio(image.lodData, level);
  // TODO: align images to their highest LOD 2^level?
  mapOffset.x -= (mapOffset.x - image.mapPosition.x) % tileSize;
  mapOffset.y -= (mapOffset.y - image.mapPosition.y) % tileSize;
  return mapOffset;
}

// bitmask of levels loaded for a given tile (smallest, LOD 0 tile)
export type LodLevelMask = number;

export function hasLevel(mask: LodLevelMask, level: number): boolean {
  return (mask & (1 << level)) !== 0;
}

export function addLevel(mask: LodLevelMask, level: number): number {
  return mask | (1 << level);
}

export function removeLevel(mask: LodLevelMask, level: number): number {
  return mask & ~(1 << level);
}

export function bestLevel(mask: LodLevelMask): number {
  if (mask === 0) return +Infinity;
  for (let i = 0; i < 32; i++) {
    if (mask & (1 << i)) return i;
  }
  return +Infinity;
}

export function iterateLevels(mask: LodLevelMask, callback: (level: number) => void) {
  for (let i = 0; i < 32; i++) {
    if (mask & (1 << i)) callback(i);
  }
}

// tile pixels / planogram units
export function pixelRatioForLevel(level: number, tileSize: number): number {
  return (TILE_CONTENT_SIZE * 2 ** -level) / tileSize;
}

export function backgroundFromLodId(id: LodId) {
  return -id - 1;
}

// TODO: consider a more elegant way to prevent collisions between background and image ids

export function isBackgroundId(id: LodId): boolean {
  return id < 0;
}

export function backgroundToLodId(id: number): LodId {
  return -1 - id;
}

// TODO: split background into 4 items?
export function backgroundDataToLodItem(data: BackgroundImageWithLod): ImageLodData | undefined {
  const params = data.virtual_params!;
  if (params === undefined || params === null) return undefined;
  const aspectRatio = data.original_width / data.original_height;
  const fitWidth = params.pagesWide;
  const fitHeight = Math.min(params.pagesHigh, params.pagesWide / aspectRatio);

  const lodLevels: LodLevelData[] = [];
  for (let lod = 0; lod < (params.worstLod ?? -1); ++lod) {
    const lodRatio = 2 ** -lod;
    const gridSize = Math.max(params.pagesWide, params.pagesHigh) * lodRatio;
    const width = params.pagesWide * lodRatio;
    const height = params.pagesHigh * lodRatio;
    const textures: Array<TileLodData | null> = [];
    const minHeight = Math.floor((gridSize - height) * 0.5);
    const maxHeight = minHeight + height;

    for (let x = 0; x < gridSize; x++)
      for (let y = 0; y < gridSize; y++) {
        const isPadding = y < minHeight || maxHeight <= y || x >= width;
        if (isPadding) textures.push(null);
        else textures.push({ url: `${lod}-${x}-${y - minHeight}` });
      }

    const urlStart = `${data.tiles_path}textures`;
    lodLevels.push({
      textures,
      lod,
      url_start: urlStart,
    });
  }

  return {
    id: backgroundToLodId(data.id),
    curator_lods: lodLevels,
    full_size: [params.pagesWide * params.pageSize, params.pagesHigh * params.pageSize],
    fit_size: [fitWidth * params.pageSize, fitHeight * params.pageSize],
    naturalResolution: [data.original_width, data.original_height],
    lods_version: 0,
  };
}

export function fallbackFitFullSize(data: Partial<ImageLodData>, lods: LodLevelData[]) {
  if (!data.full_size) {
    const bestLod = lods.reduce((best, lod) => (lod.lod < best.lod ? lod : best));
    const pow2side = computeLevelMapSize(bestLod) * TILE_SIZE;
    data.full_size = [pow2side, pow2side];
  }
  if (!data.fit_size) {
    const fullSize = new Vector2(...data.full_size!);
    const naturalResolution = data?.naturalResolution;
    const originalSize = naturalResolution ? new Vector2(...naturalResolution) : fullSize.clone();
    const aspectRatio = originalSize.x / originalSize.y;
    data.fit_size = new Vector2(Math.min(1, aspectRatio), 1 / Math.max(1, aspectRatio))
      .multiply(fullSize)
      .toArray();
  }
}
