import {Vector2} from 'three';
import {PlanogramsService} from './api/services/planograms.service';
import {ErrorOverlay} from './errors_overlay';
import {PageLoadingSpeed} from './page_loading_speed';
import {PAGE_LOADING_TYPES} from './shared/constants';
import {AnimationPathType, BackgroundImageWithLod, SphereItemType} from 'shared/interfaces/planogram';
import {
  ALIGNMENT_TYPES,
  AnimationSettings,
  AudioSettings,
  ClientSocialMedia,
  EcommercePlatformCurrency,
  EntranceAnimationSettings,
  ItemData,
  ItemLOD,
  LODSMetaData,
  MediaMetaData,
  NAVIGATION_ARROW_TYPES,
  NavigationArrow,
  OtherAsset,
  PlanogramVersion,
  PlanogramVersionSettingsControlButton,
  PlanogramVersionSettingsInfoButton,
  PlanogramVersionSettingsLogo
} from './interfaces/planogram.interface';
import {BackgroundLod, AnimationPath} from 'shared/interfaces/planogram';
import {L10nUtils} from './utils/l10n_utils';
import Router from './router';
import {AppState} from './shared/app.state';
import {isLodItem} from './utils/planogram_utils';
import {fallbackFitFullSize} from 'shared/lod/helpers';
import {EcommerceOverlaySettings} from './interfaces/planogram.interface';
import {SharingButton} from './interfaces/planogram.interface';

function migrateImageData(imageData: ItemData<LODSMetaData>) {
  imageData.lods.forEach(level => {
    level.lod = parseInt((level.lod as unknown) as string);
  });

  if (imageData.data.full_size === undefined || imageData.data.fit_size === undefined)
    fallbackFitFullSize(
      {
        ...imageData,
        id: undefined
      },
      imageData.lods
    );
  imageData.data.lods_version = imageData.data.lods_version ?? 0;
}

function migrate(version: PlanogramVersion, planogramName: string) {
  const {tiles_path, tiles_paths} = version;
  version.tiles_path = tiles_paths?.webp ?? tiles_path;

  version.primary_color = version.primary_color ?? '#181b25';
  version.secondary_color = version.secondary_color ?? '#2b3147';
  version.iframe_primary_color = version.iframe_primary_color ?? '#ffffff';
  version.iframe_secondary_color = version.iframe_secondary_color ?? '';

  const actionsJson = version.actions_json;

  actionsJson.animation_paths = actionsJson.animation_paths ?? [];
  actionsJson.backgroundColor = actionsJson.backgroundColor ?? [1, 1, 1];
  actionsJson.clustersOrder = actionsJson.clustersOrder ?? [];
  // TODO temporary solution to avoid navigation to another spheres
  actionsJson.animation_paths = actionsJson.animation_paths.map(path => ({
    ...path,
    items:
      path.type !== AnimationPathType.Custom
        ? path.items.filter(
            item => item.planogramName === planogramName && item.language === L10nUtils.getCurrentLanguage()
          )
        : path.items
  }));

  actionsJson.startPoint = actionsJson.startPoint ?? 180;

  actionsJson.items?.forEach(item => {
    if (typeof item.id !== 'string') item.id = (item.id as unknown).toString();
    item.layer = item.layer ?? 0;
    if (isLodItem(item)) migrateImageData(item);
  });
}

export class Planogram {
  name: string;
  width: number;
  height: number;
  planogramsService: PlanogramsService;

  clientName: string;

  static DEFAULT_LODS_AMOUNT = 7;
  static THRESHOLD_FOR_SWITCHING_LODS = 3;

  private detectedCurrency: string;

  size() {
    return new Vector2(this.width, this.height * 0.5);
  }

  get fixedRadius() {
    return this.width / (Math.PI * 2);
  }

  private get curvature() {
    return 3;
  }

  get largeRadius() {
    return this.fixedRadius * this.curvature;
  }

  static get ALPHA() {
    return (10.0 * Math.PI * 2) / 360;
  }

  // How many parts to split the sphere into
  static PAGES_WIDE = 256;
  static PAGES_HIGH = 64;

  static pages() {
    return new Vector2(Planogram.PAGES_WIDE, Planogram.PAGES_HIGH);
  }

  constructor(name: string) {
    this.name = name;
    this.planogramsService = new PlanogramsService();
  }

  load(): Promise<void> {
    return new Promise((resolve, reject) => {
      ErrorOverlay.hide();
      PageLoadingSpeed.startMeasure(PAGE_LOADING_TYPES.SPHERE);
      const cdnTag = document.querySelector('meta[name="serialized-planogram-version"]');
      this.detectedCurrency = document.querySelector('meta[name="currency_code"]')?.getAttribute('content');

      if (cdnTag) {
        const url = cdnTag.getAttribute('content');
        // only use the tag once, otherwise navigating to other spheres will load wrong json
        // TODO: add planogram name to PlanogramVersion JSON response so that FE can validate that the correct JSON was received
        cdnTag.remove();
        this.planogramsService
          .getPlanogramFromCdn(url)
          .then(data => {
            this.processLoadedVersion(data);
            resolve();
          })
          .catch(err => {
            this.handleErrorResponse(err);
            reject(err);
          });
        return;
      }

      this.planogramsService
        .getPlanogram(this.name)
        .then(res => {
          const version = res.planogram_version;
          const actionsJson = res.planogram_version.actions_json;
          if (typeof actionsJson === 'string')
            return this.planogramsService.getActionJson(actionsJson).then(data => {
              version.actions_json = data;
              this.processLoadedVersion(version);
              resolve();
            });
          else {
            this.processLoadedVersion(version);
            resolve();
          }
        })
        .catch(err => {
          this.handleErrorResponse(err);
          reject(err);
        });
    });
  }

  handleErrorResponse(err) {
    if (err.status === 404) {
      if (L10nUtils.getCurrentLanguage() === L10nUtils.fallbackLanguage) {
        ErrorOverlay.show404Error();
      } else {
        Router.updateLangCode(L10nUtils.fallbackLanguage);
      }
    } else if (
      err.status === 401 ||
      (err instanceof TypeError && (err?.message === 'cancelled' || err?.message === 'Load failed'))
    ) {
      // iOS return TypeError on cancelling authorization attempt
      // instead of trying to request data without credentials
      ErrorOverlay.show404Error();
    } else {
      ErrorOverlay.show500Error();
    }
    if (err.status === undefined) console.error(err);
  }

  // TODO: values copied from PlanogramVersion, take a copy of it instead
  id: number;
  versionId: number;
  isExternalDomain: boolean;
  ecommerceEnabled: boolean;
  isMultipassKeyAvailable: boolean;
  multipassRedirectUrl: string;
  ecommercePlatformName: string;
  ecommercePlatformCurrency: string;
  ecommercePlatformCurrencies: Array<EcommercePlatformCurrency>;
  ecommerceOverlaySettingsPdp: EcommerceOverlaySettings;
  ecommerceOverlaySettingsShoppingCart: EcommerceOverlaySettings;
  ecommerceOverlaySettingsSignIn: EcommerceOverlaySettings;
  ecommerceOverlaySettingsButtons: EcommerceOverlaySettings;
  isShoppingCartEnabled: boolean;
  afterParse: Function;
  faceNormalsHelper: any;
  enabledGalleryOverlay: boolean;
  tilesPath: string;
  planogramVersionControlButtons: PlanogramVersionSettingsControlButton[];
  planogramVersionLogo: PlanogramVersionSettingsLogo;
  infoButtonSetting: PlanogramVersionSettingsInfoButton;
  primaryColor: string;
  secondaryColor: string;
  sharingButton: SharingButton;
  cameraPosition: number;
  seoTitle: string;
  seoDescription: string;
  iframePrimaryColor: string;
  iframeSecondaryColor: string;
  animationSettings: AnimationSettings;
  audioSettings: AudioSettings;
  otherAssets: Record<string, OtherAsset>;
  audioBackgroundColor: string;
  volume: number;
  navigationAlignment: ALIGNMENT_TYPES;
  navigationDistributeEvenly: boolean;

  topLimit: number;
  bottomLimit: number;
  backgroundColor: [number, number, number];
  startPoint: number;
  clustersOrder: string[];
  planogramVersion: string;
  clientSocialMedias: ClientSocialMedia[];
  entranceAnimation: EntranceAnimationSettings;
  virtualTexture: BackgroundLod;

  items: ItemData[];
  animation_paths: AnimationPath[];
  navigationArrow: NavigationArrow;
  productSEO: Record<string, {title: string}>;
  itemSEO: Record<string, {title: string}>;
  itemsOrder: string[];
  background_images: BackgroundImageWithLod[];

  private processLoadedVersion(versionConfig: PlanogramVersion) {
    migrate(versionConfig, this.name);
    const planogramConfig = versionConfig.actions_json;
    this.id = versionConfig.planogram_id;
    this.isExternalDomain = versionConfig.client.is_external_domain;
    this.versionId = versionConfig.id;
    this.tilesPath = versionConfig.tiles_path;
    this.clientName = versionConfig.client.name;
    this.ecommerceEnabled = versionConfig.client?.ecommerce_platform_enabled;
    this.isMultipassKeyAvailable = versionConfig.client?.shopify_multipass_enabled;
    this.multipassRedirectUrl = versionConfig.client?.shopify_multipass_url;
    this.ecommercePlatformName = versionConfig.client?.shopping_platform?.title;
    this.ecommercePlatformCurrency = versionConfig.client?.shopping_platform?.currency ?? this.detectedCurrency;
    this.ecommercePlatformCurrencies = versionConfig.client?.currencies;
    this.width = planogramConfig.width;
    this.height = planogramConfig.height;
    this.topLimit = planogramConfig.topLimit;
    this.bottomLimit = planogramConfig.bottomLimit;
    this.seoTitle = versionConfig.seo_title;
    this.seoDescription = versionConfig.seo_desc;
    this.backgroundColor = planogramConfig.backgroundColor;
    this.startPoint = (planogramConfig.startPoint / 360.0) * planogramConfig.width;
    this.clustersOrder = planogramConfig.clustersOrder;
    this.planogramVersion = planogramConfig.planogramVersion;
    this.planogramVersionControlButtons = versionConfig.planogram_version_control_buttons;
    this.planogramVersionLogo = versionConfig.planogram_version_logo;
    this.enabledGalleryOverlay = versionConfig.enabled_gallery_overlay;
    this.infoButtonSetting = versionConfig.info_button_setting;
    this.ecommerceOverlaySettingsPdp = versionConfig.ecommerce_overlay_settings_pdp;
    this.ecommerceOverlaySettingsShoppingCart = versionConfig.ecommerce_overlay_settings_shopping_cart;
    this.ecommerceOverlaySettingsSignIn = versionConfig.ecommerce_overlay_settings_sign_in;
    this.ecommerceOverlaySettingsButtons = versionConfig.ecommerce_overlay_settings_buttons;
    this.sharingButton = versionConfig.sharing_button;
    this.primaryColor = versionConfig.primary_color;
    this.secondaryColor = versionConfig.secondary_color;
    this.iframePrimaryColor = versionConfig.iframe_primary_color;
    this.iframeSecondaryColor = versionConfig.iframe_secondary_color;
    this.cameraPosition = versionConfig.camera_position;
    this.clientSocialMedias = versionConfig.client_social_medias;
    this.animationSettings = versionConfig.animation_settings;
    this.audioSettings = versionConfig.audio;
    this.otherAssets = {
      ...versionConfig.other_assets.reduce(
        (arr, val) => ({
          ...arr,
          [val.title]: val
        }),
        {}
      ),
      ...versionConfig.navigation_arrow?.navigation_arrow_other_assets.reduce(
        (arr, val) => ({
          ...arr,
          [val.direction]: val.other_asset
        }),
        {}
      )
    };
    this.audioBackgroundColor = versionConfig.audio_background_color;
    this.entranceAnimation = versionConfig.entrance_animation;
    this.volume = versionConfig.volume;
    this.navigationAlignment = versionConfig.navigation_alignment;
    this.navigationDistributeEvenly = versionConfig.navigation_distribute_evenly;
    if (planogramConfig.virtualTexture) {
      this.virtualTexture = this.createVirtualTextureFromJSON(planogramConfig.virtualTexture);
    }

    this.items = this.createItemsFromJSON(planogramConfig.items);
    this.animation_paths = planogramConfig.animation_paths;
    this.navigationArrow = versionConfig.navigation_arrow;
    this.background_images = versionConfig.background_images;
    this.productSEO = {};
    const currLang = L10nUtils.getCurrentLanguage();
    versionConfig.planogram_version_products_seo?.forEach(
      o =>
        (this.productSEO[o.id] = {
          title: o.seo_title[currLang] ?? o.seo_title[L10nUtils.fallbackLanguage]
        })
    );
    this.itemSEO = {};
    versionConfig.planogram_version_items_seo?.forEach(
      o =>
        (this.itemSEO[o.id] = {
          title: o.seo_title
        })
    );

    if (versionConfig.planogram_legacy) {
      console.warn('Legacy spheres are not supported, please regenerate sphere');
    }

    AppState.planogramName = this.name;
    AppState.isExternalDomain = this.isExternalDomain;
    PageLoadingSpeed.completeMeasure(PAGE_LOADING_TYPES.SPHERE);
  }

  static get COLUMN_COUNT() {
    return 50;
  }

  static get ROW_COUNT() {
    return 50;
  }

  private createItemsFromJSON(items: ItemData[]) {
    const filteredItems = items.filter(
      item => !(item.type === SphereItemType.Video && (item.data as MediaMetaData).videoUrl === '')
    );

    const mappedItems: ItemData[] = filteredItems.map(item => {
      item.renderOrder = 99999 - item.layer;
      return item;
    });

    // Assign the parent object to items
    mappedItems.forEach(item => {
      item.childrenIds?.forEach(id => {
        const child = mappedItems.find(it => it.id === id);
        if (child === undefined) console.warn(`Cluster ${item.id} has unknown child ${id}`);
        else child.parent = item;
      });
    });

    // Sort the items by position
    mappedItems.sort((a, b) => {
      if (a.x < b.x) {
        return -1;
      } else if (a.x > b.x) {
        return 1;
      } else if (a.y < b.y) {
        return 1;
      }
      return -1;
    });

    this.itemsOrder = mappedItems.map(item => item.id);

    return mappedItems;
  }

  private createVirtualTextureFromJSON(json) {
    return {
      x: json.x,
      y: json.y,
      width: json.width,
      height: json.height,
      pagesWide: json.pagesWide,
      pagesHigh: json.pagesHigh,
      pageSize: json.pageSize,
      worstLod: json.worstLod,
      permanentLod: json.permanentLod,
      pageBorderWidth: json.pageBorderWidth
    };
  }

  createImage(title: OtherAsset['title'] | NAVIGATION_ARROW_TYPES, defaultSrc: string): HTMLImageElement {
    const img = document.createElement('img');
    const image = this.otherAssets[title];
    img.src = image?.url ?? defaultSrc;
    return img;
  }
}
