import {Box2} from 'three';
import {Modulo} from '../utils/moduloUtils';
import {VideoComponent} from '../components/video';
import {Planogram} from '../planogram';

const STUTTER_TARGET = 0.1;

function normalizedLpf(newValue: number, oldValue: number, eps: number, dt: number): number {
  const t = eps ** Math.max(0, dt);
  return (1 - t) * newValue + t * oldValue;
}

function updateAllowedVideos(oldValue: number, stutterCount: number, dt: number) {
  const change = stutterCount > STUTTER_TARGET ? -1 : 1;
  const eps = 0.1 ** (Math.abs(stutterCount - STUTTER_TARGET) / STUTTER_TARGET);
  return normalizedLpf(oldValue + change, oldValue, eps, dt);
}

type VideoPriority = [number, number, number, number, number];

export class VideoLimiter {
  private viewport: Box2;
  private allowedToPlay: number;
  private stutterCount: number;
  private modulo: Modulo;

  constructor(
    planogram: Planogram,
    private videos: VideoComponent[],
    private preloadLimit: number = +Infinity,
    private playingLimit: number = +Infinity
  ) {
    this.viewport = new Box2();
    this.modulo = new Modulo(planogram.size());
    this.stutterCount = STUTTER_TARGET;
    this.allowedToPlay = 2;
  }

  updateViewport(viewport: Box2) {
    this.viewport = viewport;
  }

  private priority(v: VideoComponent): VideoPriority {
    const fullSize = v.fullSize ? 0 : 1;
    const keepManuallyPlayed = !v.autoplay && v.isPlaying ? 0 : 1;
    const skipPausedManualVideos = v.autoplay ? 0 : 1;
    const visible = v.isVisible ? 0 : 1;
    const distance = this.modulo.distanceVB(v.getViewportCenter(), this.viewport);

    return [keepManuallyPlayed, skipPausedManualVideos, fullSize, distance.length(), visible];
  }

  private compare(a: VideoPriority, b: VideoPriority): number {
    let comp = 0;
    for (let i = 0; i < a.length; i++) {
      comp = a[i] - b[i];
      if (comp !== 0) break;
    }
    return comp;
  }

  private updateVideos(loadCount: number, playCount: number) {
    this.videos
      .map(v => ({v, p: this.priority(v)}))
      .sort((a, b) => this.compare(a.p, b.p))
      .map(a => a.v)
      .forEach((v, i) => {
        v.allowToLoad(i < loadCount || i < playCount);
        v.allowToPlay(i < playCount);
      });
  }

  update(dt: number) {
    dt = Math.min(1, dt); // ignore very long frames

    const playingVideos = this.videos.filter(v => v.isPlaying);
    const playingCount = playingVideos.length;
    const stutteringCount = playingVideos.filter(v => v.isStuttering()).length;

    this.stutterCount = normalizedLpf(stutteringCount / Math.max(1, playingCount), this.stutterCount, 0.1, dt);
    const target = updateAllowedVideos(this.allowedToPlay, this.stutterCount, dt);
    this.allowedToPlay = Math.min(this.playingLimit, playingCount + 1, this.videos.length, Math.max(target, 1));

    this.updateVideos(this.preloadLimit, Math.round(this.allowedToPlay));
  }
}
